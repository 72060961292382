import { WebhookEventType } from '@/schemas/entities/webhook.schema'
import { testWebhook } from '@/services/webhook.service'
import { Button } from '@/shared/ui/base/button'
import { Check, Send, X } from 'lucide-react'
import { useState } from 'react'

export function TestEventButton({ url, event_type }: { url: string; event_type: WebhookEventType }) {
    const [testStatus, setTestStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle')

    const handleTestWebhook = () => {
        setTestStatus('loading')
        testWebhook(url, event_type)
            .then(() => {
                setTestStatus('success')
                setTimeout(() => setTestStatus('idle'), 3000)
            })
            .catch(() => {
                setTestStatus('error')
                setTimeout(() => setTestStatus('idle'), 3000)
            })
    }

    return (
        <Button
            type="button"
            variant={
                testStatus === 'idle' || testStatus === 'loading'
                    ? 'outline'
                    : testStatus === 'success'
                      ? 'default'
                      : 'destructive'
            }
            onClick={handleTestWebhook}
            isLoading={testStatus === 'loading'}
            disabled={!url}
        >
            {testStatus === 'idle' || testStatus === 'loading' ? (
                <>
                    <Send className="h-4 w-4 mr-1" />
                    Send test event
                </>
            ) : testStatus === 'success' ? (
                <>
                    <Check className="h-4 w-4 mr-1" />
                    Test event sent
                </>
            ) : (
                <>
                    <X className="h-4 w-4 mr-1" />
                    Test event failed
                </>
            )}
        </Button>
    )
}
