import { Separator } from '@/shared/ui/base/separator'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism'

export function CodeBlock({ curl }: { curl: string }) {
    return (
        <div className="flex flex-col bg-[#1E1E1E] rounded-md">
            <div className="flex justify-between">
                <p className="text-sm text-white p-2">
                    <span className="text-green-500 mr-2">POST</span> {getEndpointFromCurl(curl)}
                </p>
            </div>
            <Separator className="bg-white" />
            <SyntaxHighlighter
                className="max-h-[70vh] overflow-scroll m-0"
                language="bash"
                style={vscDarkPlus}
                showLineNumbers={true}
            >
                {curl}
            </SyntaxHighlighter>
        </div>
    )
}

const getEndpointFromCurl = (curl: string) => {
    const baseUrl = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL
    if (curl.includes(baseUrl)) {
        return curl.split(baseUrl)[1].split('"')[0]
    }
    return ''
}
