import { useImpersonating } from '@/components/hooks/authentication/use-impersonating'
import { Input } from '@/components/ui/base/input'
import { Label } from '@/components/ui/base/label'
import { useToast } from '@/components/ui/base/use-toast'
import { UserWithTeam } from '@/schemas/entities/user'
import { impersonate } from '@/services/auth/auth.service'
import { getMyProfile, rotateToken } from '@/services/user.service'
import { Button } from '@/shared/ui/base/button'
import { Separator } from '@/shared/ui/base/separator'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import { CopyIcon, RefreshCcw } from 'lucide-react'
import { useEffect, useState } from 'react'

function APITokenPage() {
    const { data: me = null, refetch } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    })
    const { isImpersonating, impersonatingEmail } = useImpersonating()
    const [isRotating, setIsRotating] = useState(false)

    const { toast } = useToast()
    useEffect(() => {
        document.title = 'API Key · Settings · TheirStack'
    }, [])

    const handleCopyTokenToClipboard = () => {
        if (!me) return
        navigator.clipboard.writeText(me.token)
        toast({ title: 'Copied to clipboard' })
    }

    const handleRotateToken = async () => {
        if (!me) return
        setIsRotating(true)
        await rotateToken()
            .then((new_token: string) => {
                if (isImpersonating && impersonatingEmail) {
                    impersonate(impersonatingEmail, new_token)
                }
                refetch().then(() => {
                    setIsRotating(false)
                    toast({ title: 'Token refreshed' })
                })
            })
            .catch(() => {
                toast({ title: 'Failed to refresh token', variant: 'destructive' })
                setIsRotating(false)
            })
    }

    return (
        <div className="space-y-6">
            <div>
                <h3 className="text-lg font-medium">API</h3>
                <p className="text-sm text-muted-foreground">Manage your API access and usage.</p>
            </div>
            <Separator />
            <div className="space-y-3">
                <Label>API Key</Label>
                <p className="text-sm text-muted-foreground">
                    API keys allow you to access your account programmatically via the&nbsp;
                    <a
                        href="https://api.theirstack.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                    >
                        TheirStack API
                    </a>
                    . Please keep your key secret, it can be used to access your account without a password.
                </p>
                <div className="flex space-x-2">
                    <Input
                        disabled={true}
                        className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 w-80"
                        value="**********************************"
                    />
                    <Button variant="outline" onClick={handleCopyTokenToClipboard}>
                        <CopyIcon className="h-4 w-4" strokeWidth={1.5} />
                    </Button>
                    <Button variant="outline" onClick={handleRotateToken} isLoading={isRotating}>
                        <RefreshCcw className="h-4 w-4" strokeWidth={1.5} />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default APITokenPage
