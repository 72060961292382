import { SavedSearch } from '@/schemas/entities/saved-search.schema'
import { getAllSearches } from '@/services/saved-search.service'
import { useQuery } from '@tanstack/react-query'

export function useSavedSearches() {
    const {
        data: searches = [],
        isLoading,
        isError,
    } = useQuery<SavedSearch[]>({
        queryKey: ['searches'],
        queryFn: () => getAllSearches(),
    })

    const getSavedSearch = (id: number) => {
        return searches.find((search) => search.id === id)
    }

    return { searches, isLoading, isError, getSavedSearch }
}
