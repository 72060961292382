import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@/shared/ui/base/dialog'
import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react'
import { OpenDialogHandle } from './dialog-handle-interface'

export interface GenericDialogProps {
    title: string
    description: string
    button: ReactNode
}

const CustomDialog = forwardRef<OpenDialogHandle, GenericDialogProps>((props, ref) => {
    const [show, setShow] = useState(false)

    useImperativeHandle(ref, () => ({
        open: () => setShow(true),
        close: () => setShow(false),
    }))

    return (
        <Dialog open={show} onOpenChange={(open) => setShow(open)}>
            <DialogContent>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogDescription>{props.description}</DialogDescription>
                <div className="flex items-center justify-center mt-6">{props.button}</div>
            </DialogContent>
        </Dialog>
    )
})

export default CustomDialog
