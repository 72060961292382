import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { Textarea } from '@/components/ui/base/textarea'
import { isURL } from '@/lib/http-utils'
import { TechnologyCategory } from '@/schemas/entities/technology-category'
import { getAllTechnologyCategories } from '@/services/technology-category.service'
import { fetchTechnologyDetails } from '@/services/technology-explorer.service'
import { Button } from '@/shared/ui/base/button'
import { Combobox } from '@/shared/ui/base/combobox'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'

const FormSchema = z.object({
    url: z.string().refine((value) => isURL(value), { message: 'Invalid URL' }),
    name: z.string().min(1, { message: 'Name is required' }),
    logo: z.string().url({ message: 'Invalid Logo URL' }).min(1, { message: 'Logo URL is required' }),
    oneLiner: z.string().min(1, { message: 'One-liner is required' }),
    longDescription: z.string().min(1, { message: 'Long description is required' }),
    category_slug: z.string().min(1, { message: 'Category is required' }),
})

export const NewTechnologyForm = () => {
    const [categories, setCategories] = useState<TechnologyCategory[]>([])
    const [isLoadingCategories, setIsLoadingCategories] = useState(true)

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            url: '',
            name: '',
            logo: '',
            oneLiner: '',
            longDescription: '',
            category_slug: '',
        },
    })

    const url = useWatch({ control: form.control, name: 'url' })
    const logo = useWatch({ control: form.control, name: 'logo' })

    useEffect(() => {
        if (url) {
            fetchTechnologyDetails(url).then((details) => {
                form.setValue('name', details.name)
                form.setValue('logo', details.logo)
                form.setValue('oneLiner', details.oneLiner)
                form.setValue('longDescription', details.longDescription)
            })
        }
    }, [url, form])

    useEffect(() => {
        setIsLoadingCategories(true)
        getAllTechnologyCategories()
            .then(setCategories)
            .finally(() => setIsLoadingCategories(false))
    }, [])

    const onSubmit = (data: z.infer<typeof FormSchema>) => {
        console.log('Technology Details:', data)
    }

    return (
        <div className="w-2/3 mx-auto">
            <div className="grid grid-cols-1 gap-4">
                <h1 className="text-2xl font-semibold text-center">Add Technology</h1>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                        <FormField
                            control={form.control}
                            name="url"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Website URL</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="url"
                                            id="technology-url"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="text"
                                            id="technology-name"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="logo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Logo URL</FormLabel>
                                    <div className="flex flex-row gap-2 justify-between">
                                        <div className="flex-grow">
                                            <FormControl>
                                                <Input
                                                    type="url"
                                                    id="technology-logo"
                                                    {...field}
                                                    className="border border-gray-300 rounded-md p-2"
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                        {logo && (
                                            <div className="">
                                                <img
                                                    src={logo}
                                                    alt="Technology Logo"
                                                    className="w-16 h-16 object-cover border border-gray-300"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="oneLiner"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>One-liner</FormLabel>
                                    <FormControl>
                                        <Textarea
                                            id="technology-one-liner"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="longDescription"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Description</FormLabel>
                                    <FormControl>
                                        <Textarea
                                            id="technology-long-description"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="category_slug"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Category</FormLabel>
                                    <FormControl>
                                        <Combobox
                                            items={categories.map((cat) => ({
                                                value: cat.slug,
                                                label: cat.name,
                                            }))}
                                            value={field.value?.toString() || ''}
                                            onChange={(value) => field.onChange(value)}
                                            placeholder="Select a category"
                                            searchPlaceholder="Search categories..."
                                            emptyText="No categories found"
                                            isLoading={isLoadingCategories}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <Button type="submit" className="bg-primary text-white rounded-md p-2">
                            Submit
                        </Button>
                    </form>
                </Form>
            </div>
        </div>
    )
}
