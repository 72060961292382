import { SheetTitle } from '@/components/ui/base/sheet'
import { ChevronLeft } from 'lucide-react'

interface SheetHeaderProps {
    title: string
    showBackButton: boolean
    onBack: () => void
}

export function SheetHeader({ title, showBackButton, onBack }: SheetHeaderProps) {
    return (
        <div className="flex items-center mb-6">
            {showBackButton && (
                <button
                    onClick={onBack}
                    className="p-2 hover:bg-gray-100 rounded-full mr-2"
                    aria-label="Go back to home"
                >
                    <ChevronLeft className="h-5 w-5" />
                </button>
            )}
            <SheetTitle>{title}</SheetTitle>
        </div>
    )
}
