import { NavigateToPage } from '@/components/ui/sheet/paginated-sheet/base/paginated-sheet'
import { WebhookList } from '@/components/ui/webhooks/webhook-list'
import { EVENT_CREATE_WEBHOOK_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { Plus, SearchIcon, X } from 'lucide-react'
import { useState } from 'react'
import { WEBHOOK_DETAILS_PAGE_SLUG } from '../../instances/api-webhook-sheet'

export function Webhooks({ navigateToPage, searchId }: { navigateToPage: NavigateToPage; searchId?: number }) {
    const [selectedSearchId, setSelectedSearchId] = useState<number | undefined>(searchId)
    return (
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <WebhookFilters selectedSearchId={selectedSearchId} setSelectedSearchId={setSelectedSearchId} />
                <Button
                    onClick={() => {
                        sendEvent(EVENT_CREATE_WEBHOOK_MODAL_VIEW)
                        navigateToPage('webhook-new')
                    }}
                    size="sm"
                >
                    <Plus className="h-4 w-4 mr-2" />
                    New Webhook
                </Button>
            </div>
            <WebhookList
                handleView={(webhookId: number) => {
                    navigateToPage(WEBHOOK_DETAILS_PAGE_SLUG, { id: webhookId.toString() })
                }}
                searchId={selectedSearchId}
            />
        </div>
    )
}

export function WebhookFilters({
    selectedSearchId,
    setSelectedSearchId,
}: {
    selectedSearchId?: number
    setSelectedSearchId: (searchId: number | undefined) => void
}) {
    if (!selectedSearchId) return <div className="flex" />

    return (
        <Card className="h-9 flex items-center px-2 gap-x-2 text-sm flex-shrink-0">
            <div className="flex space-x-2 items-center">
                <SearchIcon className="h-4 w-4" />
                <p className="text-sm">Search</p>
            </div>
            <p className="text-sm text-gray-500">is</p>
            <p className="text-sm "> current</p>
            <div
                className="flex items-center justify-center hover:bg-slate-100 cursor-pointer px-1 pr-1  rounded-tr-md rounded-br-md text-slate-500 hover:text-red-500"
                onClick={() => setSelectedSearchId(undefined)}
            >
                <X className="h-4 w-4" strokeWidth={1.5} />
            </div>
        </Card>
    )
}
