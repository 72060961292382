import { cn } from '@/lib/utils'
import { EVENT_API_CURL_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { Button } from '@/shared/ui/base/button'
import { Braces } from 'lucide-react'
import { PaginatedSheet, SheetPage } from '../base/paginated-sheet'
import API from '../pages/api/api'
import { Make } from '../pages/make'
import { N8N } from '../pages/n8n'

export const HOME_PAGE_SLUG = 'home'
export const API_PAGE_SLUG = 'api'
export const WEBHOOKS_PAGE_SLUG = 'webhooks'
export const WEBHOOK_NEW_PAGE_SLUG = 'webhook-new'
export const WEBHOOK_EDIT_PAGE_SLUG = 'webhook-edit'
export const WEBHOOK_DETAILS_PAGE_SLUG = 'webhook-details'
export const MAKE_PAGE_SLUG = 'make'
export const N8N_PAGE_SLUG = 'n8n'
export const CLAY_PAGE_SLUG = 'clay'

export default function APISheet({
    curl,
    entityName,
    className = '',
    variant = 'ghost',
    buttonTitle = 'API',
}: {
    curl: string
    entityName: 'jobs' | 'companies' | 'technologies'
    className?: string
    variant?: 'ghost' | 'outline'
    buttonTitle?: string
}): React.ReactNode {
    const pages: SheetPage[] = [
        {
            id: HOME_PAGE_SLUG,
            is_home: true,
            title: 'Pull this list with our API',
            content: ({ navigateToPage }) => <API curl={curl} navigateToPage={navigateToPage} />,
            children: [
                {
                    id: MAKE_PAGE_SLUG,
                    title: `Pull ${entityName} from Make.com`,
                    content: () => <Make curl={curl} />,
                },
                {
                    id: N8N_PAGE_SLUG,
                    title: `Pull ${entityName} from N8N`,
                    content: () => <N8N curl={curl} />,
                },
            ],
        },
    ]
    return (
        <PaginatedSheet
            className={className}
            trigger={<TriggerButton variant={variant} buttonTitle={buttonTitle} />}
            pages={pages}
        />
    )
}

function TriggerButton({ variant, buttonTitle }: { variant?: 'ghost' | 'outline'; buttonTitle?: string }) {
    return (
        <Button
            variant={variant}
            size="sm"
            className={cn('text-sm font-normal')}
            onClick={() => {
                sendEvent(EVENT_API_CURL_MODAL_VIEW)
            }}
        >
            <Braces className="h-4 w-4 md:mr-2" />
            <p className="hidden lg:inline">{buttonTitle}</p>
        </Button>
    )
}
