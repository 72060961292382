import { useSavedSearches } from '@/components/hooks/use-saved-sarches'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/base/select'
import { EditWebhook, editWebhookSchema, Webhook } from '@/schemas/entities/webhook.schema'
import { editWebhook } from '@/services/webhook.service'
import { Button } from '@/shared/ui/base/button'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { TestEventButton } from './test-event-button'

interface EditWebhookFormProps {
    webhook: Webhook
    onCancel: () => void
    onSuccess: () => void
}

export function EditWebhookForm({ webhook, onCancel, onSuccess }: EditWebhookFormProps) {
    const { searches } = useSavedSearches()
    const form = useForm<EditWebhook>({
        resolver: zodResolver(editWebhookSchema),
        defaultValues: {
            event_type: webhook.event_type,
            url: webhook.url,
            description: webhook.description,
            search_id: webhook.search_id,
        },
    })
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: (data: EditWebhook) =>
            editWebhook(webhook.id!, data).then(() =>
                queryClient.invalidateQueries({ queryKey: ['webhook', webhook.id] })
            ),
        onSuccess,
    })

    const onSubmit = (data: EditWebhook) => {
        if (!webhook.id) return
        mutate(data)
    }

    if (!webhook) {
        return (
            <div className="text-center text-gray-500 py-8">
                <p>Webhook not found</p>
            </div>
        )
    }

    return (
        <div className="space-y-4">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                    <FormField
                        control={form.control}
                        name="search_id"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Saved Search</FormLabel>
                                <p className="text-xs text-gray-500">
                                    This search's filters determine which records will trigger the webhook.
                                </p>
                                <FormControl>
                                    <Select onValueChange={field.onChange} defaultValue={field.value.toString()}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a saved search" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {searches
                                                .filter((search) =>
                                                    form.getValues('event_type') == 'job_new'
                                                        ? search.type == 'jobs'
                                                        : search.type == 'companies'
                                                )
                                                .map((search) => (
                                                    <SelectItem
                                                        key={search.id}
                                                        value={search.id.toString()}
                                                        className="inline-flex items-center gap-2"
                                                    >
                                                        {search.name}
                                                    </SelectItem>
                                                ))}
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="url"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Webhook URL*</FormLabel>
                                <p className="text-xs text-gray-500">The URL where webhook events will be sent.</p>
                                <FormControl>
                                    <Input placeholder="https://" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <div className="flex items-center space-x-2 justify-end">
                        <TestEventButton url={form.getValues('url')} event_type={form.getValues('event_type')} />
                    </div>

                    <FormField
                        control={form.control}
                        name="description"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Description</FormLabel>
                                <FormControl>
                                    <Input placeholder="An optional description of the destination..." {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <div className="flex justify-end space-x-2">
                        <Button variant="outline" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={isPending}>
                            Save changes
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    )
}
