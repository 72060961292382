import { useToast } from '@/components/ui/base/use-toast'
import { fromCurlToN8N } from '@/lib/third-party-scripts/n8n'
import { Button } from '@/shared/ui/base/button'

export function N8N({ curl }: { curl: string }) {
    const { toast } = useToast()
    const copyCurlToClipboard = (_curl: string, title: string) => {
        navigator.clipboard.writeText(_curl)
        toast({ title })
    }

    return (
        <div className="flex flex-col items-start justify-center steps-wrapper">
            <div className="steps relative ml-4 border-l pl-8">
                <h3 className="step">Go to N8N</h3>
                <p className="text-sm">
                    Go to{' '}
                    <a href="https://n8n.io" target="_blank" rel="noopener noreferrer" className="underline">
                        N8N
                    </a>{' '}
                    and create a new workflow or edit an existing one.
                </p>
            </div>
            <div className="steps relative ml-4 border-l pl-8">
                <h3 className="step">Copy the HTTP Request</h3>
                <p className="text-sm">
                    Click the button below to copy the HTTP request to TheirStack API to your clipboard.
                </p>
                <Button
                    className="mt-4"
                    onClick={() => copyCurlToClipboard(fromCurlToN8N(curl), 'HTTP request copied to clipboard')}
                >
                    Copy HTTP Request
                </Button>
            </div>

            <div className="steps relative ml-4 border-l pl-8">
                <h3 className="step">Paste the HTTP Request</h3>
                <p className="text-sm">Paste the HTTP request into your workflow with CTRL+V or CMD+V.</p>
                <img src="/paste-http-module-n8n.gif" className="w-2/3 mt-6 rounded-md" />
            </div>
        </div>
    )
}
