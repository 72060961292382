import { getWebhookExecutionCountPerDay } from '@/services/webhook.service'
import { useQuery } from '@tanstack/react-query'

export function useWebhookExecutionCountPerDay(
    start_datetime?: Date,
    end_datetime?: Date,
    webhook_id?: number,
    enabled: boolean = true
) {
    const { data: webhookExecutionCount = [], isLoading } = useQuery({
        queryKey: ['webhook-execution-count-per-day', webhook_id],
        queryFn: () =>
            getWebhookExecutionCountPerDay(start_datetime || new Date(), end_datetime || new Date(), webhook_id),
        enabled: enabled,
    })
    return { webhookExecutionCount, isLoading }
}
