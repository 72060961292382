import { useToast } from '@/components/ui/base/use-toast'
import { fromCurlToMake } from '@/lib/third-party-scripts/make'
import { Button } from '@/shared/ui/base/button'

export function Make({ curl }: { curl: string }) {
    const { toast } = useToast()
    const copyCurlToClipboard = (_curl: string, title: string) => {
        navigator.clipboard.writeText(_curl)
        toast({ title })
    }

    return (
        <div className="flex flex-col items-start justify-center steps-wrapper">
            <div className="steps relative ml-4 border-l pl-8">
                <h3 className="step">Go to Make.com</h3>
                <p className="text-sm">
                    Go to{' '}
                    <a href="https://make.com" target="_blank" rel="noopener noreferrer" className="underline">
                        Make.com
                    </a>{' '}
                    and create a new scenario or edit an existing one.
                </p>
            </div>
            <div className="steps relative ml-4 border-l pl-8">
                <h3 className="step">Copy the HTTP module</h3>
                <p className="text-sm">
                    Click the button below to copy the HTTP request to TheirStack API to your clipboard.
                </p>
                <Button
                    className="mt-4"
                    onClick={() => copyCurlToClipboard(fromCurlToMake(curl), 'HTTP module copied to clipboard')}
                >
                    Copy HTTP module
                </Button>
            </div>

            <div className="steps relative ml-4 border-l pl-8">
                <h3 className="step">Paste the HTTP module</h3>
                <p className="text-sm">Paste the HTTP module into your scenario with CTRL+V or CMD+V. </p>
                <img src="/paste-http-module-make.gif" className="w-2/3 mt-6 rounded-md" />
            </div>
        </div>
    )
}
