import { getWebhook } from '@/services/webhook.service'
import { useQuery } from '@tanstack/react-query'

export function useWebhook(id: number) {
    const { data: webhook, isLoading } = useQuery({
        queryKey: ['webhook', id],
        queryFn: () => getWebhook(id),
    })
    return { webhook, isLoading }
}
