import { useWebhooks } from '@/components/hooks/use-webhooks'
import { cn } from '@/lib/utils'
import { EVENT_API_CURL_MODAL_VIEW, EVENT_CREATE_WEBHOOK_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { Button } from '@/shared/ui/base/button'
import { capitalizeFirstLetter } from '@/shared/utils/string-utils'
import { Braces, Webhook } from 'lucide-react'
import { PaginatedSheet, SheetPage } from '../base/paginated-sheet'
import API from '../pages/api/api'
import { Make } from '../pages/make'
import { N8N } from '../pages/n8n'
import { WebhookDetailsPage } from '../pages/webhooks/webhook-details'
import { EditWebhook } from '../pages/webhooks/webhook-edit'
import { NewWebhook } from '../pages/webhooks/webhook-new'
import { Webhooks } from '../pages/webhooks/webhooks'

export const HOME_PAGE_SLUG = 'home'
export const API_PAGE_SLUG = 'api'
export const WEBHOOKS_PAGE_SLUG = 'webhooks'
export const WEBHOOK_NEW_PAGE_SLUG = 'webhook-new'
export const WEBHOOK_EDIT_PAGE_SLUG = 'webhook-edit'
export const WEBHOOK_DETAILS_PAGE_SLUG = 'webhook-details'
export const MAKE_PAGE_SLUG = 'make'
export const N8N_PAGE_SLUG = 'n8n'
export const CLAY_PAGE_SLUG = 'clay'

export default function APIWebhooksSheet({
    curl,
    entityName,
    searchId,
    n_records,
    n_records_per_day,
}: {
    curl: string
    entityName: 'jobs' | 'companies'
    searchId?: number
    n_records?: number
    n_records_per_day?: number
}): React.ReactNode {
    const pages: SheetPage[] = [
        {
            id: HOME_PAGE_SLUG,
            is_home: true,
            title: 'API & Webhook',
            content: ({ navigateToPage }) => <HomePage navigateToPage={navigateToPage} entityName={entityName} />,
            children: [
                {
                    id: API_PAGE_SLUG,
                    title: `${capitalizeFirstLetter(entityName)} API`,
                    content: ({ navigateToPage }) => <API curl={curl} navigateToPage={navigateToPage} />,
                },
                {
                    id: WEBHOOKS_PAGE_SLUG,
                    title: 'Webhooks',
                    content: ({ navigateToPage }) => <Webhooks navigateToPage={navigateToPage} searchId={searchId} />,
                },
                {
                    id: WEBHOOK_NEW_PAGE_SLUG,
                    title: 'New Webhook',
                    content: ({ navigateToPage }) => (
                        <NewWebhook
                            navigateToPage={navigateToPage}
                            searchId={searchId}
                            eventType={entityName === 'jobs' ? 'job_new' : 'company_new'}
                            n_records={n_records}
                            n_records_per_day={n_records_per_day}
                        />
                    ),
                },
                {
                    id: MAKE_PAGE_SLUG,
                    title: `Pull ${entityName} from Make.com`,
                    content: () => <Make curl={curl} />,
                },
                {
                    id: N8N_PAGE_SLUG,
                    title: `Pull ${entityName} from N8N`,
                    content: () => <N8N curl={curl} />,
                },
                {
                    id: CLAY_PAGE_SLUG,
                    title: 'Clay',
                    content: () => <div>Clay content</div>,
                },
                {
                    id: WEBHOOK_EDIT_PAGE_SLUG,
                    title: 'Edit Webhook',
                    content: ({ navigateToPage }) => <EditWebhook navigateToPage={navigateToPage} />,
                },
                {
                    id: WEBHOOK_DETAILS_PAGE_SLUG,
                    title: 'Webhook Details',
                    content: ({ navigateToPage }) => <WebhookDetailsPage navigateToPage={navigateToPage} />,
                },
            ],
        },
    ]
    return <PaginatedSheet trigger={<TriggerButton />} pages={pages} />
}

function TriggerButton() {
    return (
        <Button
            variant="ghost"
            size="sm"
            className={cn('text-sm font-normal')}
            onClick={() => {
                sendEvent(EVENT_API_CURL_MODAL_VIEW)
            }}
        >
            <Braces className="h-4 w-4 md:mr-2" />
            <p className="hidden lg:inline">API & Webhooks</p>
        </Button>
    )
}

function HomePage({ navigateToPage, entityName }: { navigateToPage: (pageId: string) => void; entityName: string }) {
    const { webhooks, isLoading } = useWebhooks()
    return (
        <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
                <Button
                    variant="outline"
                    size="none"
                    className="flex flex-row items-center justify-between gap-8 border p-2 "
                    onClick={() => navigateToPage('api')}
                >
                    <div className="flex flex-col gap-2 items-start justify-center w-fit flex-wrap">
                        <h2 className="text-lg">{capitalizeFirstLetter(entityName)} API</h2>
                        <p className="text-xs text-gray-500 text-wrap text-start font-normal">
                            Pull this list of jobs programmatically with our API.
                        </p>
                    </div>
                    <Braces className="w-8 h-8 flex-nowrap" strokeWidth={1.5} />
                </Button>
                <Button
                    variant="outline"
                    size="none"
                    className="flex flex-row items-center justify-between w-full gap-8 border p-2"
                    onClick={() => {
                        const pageId = webhooks.length > 0 || isLoading ? WEBHOOKS_PAGE_SLUG : WEBHOOK_NEW_PAGE_SLUG
                        if (pageId === WEBHOOK_NEW_PAGE_SLUG) {
                            sendEvent(EVENT_CREATE_WEBHOOK_MODAL_VIEW)
                        }
                        navigateToPage(pageId)
                    }}
                >
                    <div className="flex flex-col gap-2 items-start">
                        <h2 className="text-lg">Webhooks</h2>
                        <p className="text-xs text-gray-500 text-wrap text-start font-normal">
                            Be notified when a new job is posted matching your search criteria. Webhooks allow you to
                            receive HTTP requests whenever a specific event (eg: new job posted) occurs in TheirStack.
                        </p>
                    </div>
                    <Webhook className="w-8 h-8 flex-shrink-0" strokeWidth={1.5} />
                </Button>
            </div>
        </div>
    )
}
