import { initSentry } from '@/lib/third-party-scripts/sentry'
import { KindeUser } from '@kinde-oss/kinde-auth-pkce-js'
import * as Sentry from '@sentry/react'
import posthog, { Properties } from 'posthog-js'
import { getLocation } from './geolocation.service'

/*
Naming conventions:
- Only use lowercase letters.
- Use present-tense verbs, e.g., "submit" and "create" instead of "submitted" and "created".
- Use snake case, i.e., signup_button_click

For more information, see: https://posthog.com/product-engineers/5-ways-to-improve-analytics-data#1-implement-a-naming-convention 
*/

export const EVENT_ONBOARDING_QUESTIONS_VIEW = 'onboarding_questions_view'
export const EVENT_ONBOARDING_QUESTIONS_SUBMIT = 'onboarding_questions_submit'

export const EVENT_UPGRADE_MODAL_VIEW = 'upgrade_modal_view'

export const EVENT_CONTACT_SALES_CLICK = 'contact_sales_click'
export const EVENT_SUBSCRIBE_CLICK = 'subscribe_click'

export const EVENT_EXPORT_CLICK = 'export_click'
export const EVENT_EXPORT_MODAL_VIEW = 'export_modal_view'

export const EVENT_FIND_PEOPLE_CLICK = 'find_people_click'
export const EVENT_FILTERS_MODAL_OPEN = 'filters_modal_open'

export const EVENT_API_CURL_MODAL_VIEW = 'api_curl_modal_view'
export const EVENT_API_CURL_MODAL_COPY = 'api_curl_modal_copy'

export const EVENT_ADD_TEAM_MEMEBER_MODAL_VIEW = 'add_team_member_modal_view'
export const EVENT_ADD_TEAM_MEMBER_CLICK = 'add_team_member_click'

export const EVENT_ENRICH_MODAL_VIEW = 'enrich_modal_view'
export const EVENT_ENRICH_RUN_CLICK = 'enrich_run_click'

export const EVENT_CREATE_WEBHOOK_MODAL_VIEW = 'create_webhook_modal_view'
export const EVENT_CREATE_WEBHOOK_CLICK = 'create_webhook_click'

export function initTracking(user: KindeUser) {
    if (import.meta.env.MODE === 'production' && !user.email?.includes('@theirstack.com')) {
        initSentry()
        const email = user.email || ''
        Sentry.setUser({ id: email, email: email, username: email })
        posthog.identify(email, { email: user.email, name: user.given_name + ' ' + user.family_name })

        getLocation()
            .then((location) => {
                recordSession(location.country_code)
            })
            .catch((error) => {
                console.error(error)
                recordSession('US')
            })
    }
}

function recordSession(country_code: string) {
    if (!['PA', 'IN', 'BG'].includes(country_code)) {
        posthog.startSessionRecording({ sampling: false })
    }
}

export function sendEvent(event: string, properties?: Properties) {
    if (import.meta.env.MODE === 'production') {
        posthog.capture(event, properties)
    }
}

export function setBillingPlan(is_free_user: boolean, plan: string) {
    posthog.setPersonProperties({
        billing_plan: plan,
        is_free_user: is_free_user,
    })
}

export function getSessionId() {
    try {
        return posthog.get_session_id()
    } catch (e) {
        return ''
    }
}
