import { KindeProvider } from '@kinde-oss/kinde-auth-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './global.css'
import { AuthenticationGuard } from './lib/authentication/authentication-guard'
import SignUpPage from './pages/signup/signup.page'
import { signup } from './services/user.service'

import { PostHogProvider } from 'posthog-js/react'
import { KindeUser, State } from './components/hooks/authentication/use-authentication'
import CompanyListDetailsPage from './pages/company-list/[id]/company-list-details-page'
import CompanylistPage from './pages/company-list/company-list.page'
import HomePage from './pages/home/home.page'
import MainLayout from './pages/main-layout'
import NotFoundPage from './pages/not-found/not-found.page'
import SearchCompaniesPage from './pages/search/companies/[id]/company-search.page'
import NewSearchCompaniesPage from './pages/search/companies/new/company-search.page'
import JobSearchDetailsPage from './pages/search/jobs/[id]/job-search.page'
import NewJobSearchPage from './pages/search/jobs/new/job-search.page'
import APITokenPage from './pages/settings/api-key/api-key.page'
import APIPage from './pages/settings/api/api.page'
import BillingPage from './pages/settings/billing/billing.page'
import IntegrationsPage from './pages/settings/integrations/integrations.page'
import ProfilePage from './pages/settings/profile/profile.page'
import SettingsLayout from './pages/settings/settings-layout'
import TeamPage from './pages/settings/team/team.page'
import { WebhookEditPage } from './pages/settings/webhooks/[id]/edit/webhook-edit.page'
import { WebhookDetailsPage } from './pages/settings/webhooks/[id]/webhook-details.page'
import { NewWebhookPage } from './pages/settings/webhooks/new/new-webhook.page'
import { WebhooksPage } from './pages/settings/webhooks/webhooks.page'
import { NewTechnologyPage } from './pages/technology/new/new-technology.page'
import UserBlockedPage from './pages/user-blocked/user-blocked.page'
import {
    COMPANY_LIST_ID_PATH,
    COMPANY_LIST_PATH,
    COMPANY_SEARCH_ID_PATH,
    COMPANY_SEARCH_NEW_PATH,
    COMPANY_SEARCH_PATH,
    HOME_PATH,
    JOB_SEARCH_ID_PATH,
    JOB_SEARCH_NEW_PATH,
    JOB_SEARCH_PATH,
    SEARCH_PATH,
    SIGNUP_PATH,
    TECHNOLOGY_NEW_PATH,
    TECHNOLOGY_PATH,
} from './paths'

const onRedirectFromLoginOrSignUpPage = async (user: KindeUser, state: State | undefined) => {
    const userSignedUp = await signup(user.given_name || '', user.family_name || '', user.email || '')

    if (userSignedUp === 'email_not_allowed') {
        window.location.replace('/user-blocked')
        return
    }
    if (userSignedUp === 'created') {
        localStorage.setItem('ask-onboarding-questions', 'true')
    }
    if (state?.redirectTo && state.redirectTo !== '/search' && state.redirectTo !== '/home') {
        window.location.replace(state.redirectTo)
    } else if (userSignedUp === 'created') {
        /* If the user is created, we need to reload the page to make sure all requests are authenticated */
        window.location.reload()
    }
}

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <PostHogProvider
            apiKey={import.meta.env.VITE_POSTHOG_KEY}
            options={{
                api_host: import.meta.env.VITE_POSTHOG_HOST,
                disable_session_recording: true,
                autocapture: false,
            }}
        >
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <KindeProvider
                        clientId={import.meta.env.VITE_KINDE_CLIENT_ID}
                        domain={import.meta.env.VITE_KINDE_DOMAIN}
                        logoutUri={import.meta.env.VITE_REACT_APP_API_SERVER_URL}
                        redirectUri={import.meta.env.VITE_REACT_APP_API_SERVER_URL + HOME_PATH}
                        isDangerouslyUseLocalStorage={true}
                        onRedirectCallback={onRedirectFromLoginOrSignUpPage}
                    >
                        <Routes>
                            <Route path="/" element={<Navigate to={HOME_PATH} />} />
                            <Route path={SIGNUP_PATH} element={<SignUpPage />} />
                            <Route path="user-blocked" element={<UserBlockedPage />} />
                            <Route element={<AuthenticationGuard component={MainLayout} />}>
                                <Route path={HOME_PATH} element={<HomePage />} />

                                <Route path={SEARCH_PATH} element={<Navigate to={HOME_PATH} />} />
                                <Route path={JOB_SEARCH_PATH} element={<Navigate to={JOB_SEARCH_NEW_PATH} />} />
                                <Route path={JOB_SEARCH_NEW_PATH} element={<NewJobSearchPage />} />
                                <Route path={JOB_SEARCH_ID_PATH} element={<JobSearchDetailsPage />} />

                                <Route path={COMPANY_SEARCH_PATH} element={<Navigate to={COMPANY_SEARCH_NEW_PATH} />} />
                                <Route path={COMPANY_SEARCH_NEW_PATH} element={<NewSearchCompaniesPage />} />
                                <Route path={COMPANY_SEARCH_ID_PATH} element={<SearchCompaniesPage />} />

                                <Route path={COMPANY_LIST_PATH} element={<CompanylistPage />} />
                                <Route path={COMPANY_LIST_ID_PATH} element={<CompanyListDetailsPage />} />

                                <Route path={TECHNOLOGY_PATH} element={<Navigate to={TECHNOLOGY_NEW_PATH} />} />
                                <Route path={TECHNOLOGY_NEW_PATH} element={<NewTechnologyPage />} />

                                <Route path="/settings" element={<SettingsLayout />}>
                                    <Route path="*" element={<Navigate to="/settings/profile" />} />
                                    <Route path="profile" element={<ProfilePage />} />
                                    <Route path="billing" element={<BillingPage />} />
                                    <Route path="api" element={<APIPage />} />
                                    <Route path="api-key" element={<APITokenPage />} />
                                    <Route path="team" element={<TeamPage />} />
                                    <Route path="integrations" element={<IntegrationsPage />} />
                                    <Route path="webhooks" element={<WebhooksPage />} />
                                    <Route path="webhooks/:id" element={<WebhookDetailsPage />} />
                                    <Route path="webhooks/new" element={<NewWebhookPage />} />
                                    <Route path="webhooks/:id/edit" element={<WebhookEditPage />} />
                                </Route>
                                <Route path="*" element={<NotFoundPage />} />
                            </Route>
                        </Routes>
                    </KindeProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </PostHogProvider>
    </React.StrictMode>
)
