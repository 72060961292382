import { useSavedSearches } from '@/components/hooks/use-saved-sarches'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { RadioGroup, RadioGroupItem } from '@/components/ui/base/radio-group'
import {
    createWebhookSchema,
    WebhookEventType,
    webhookLabels,
    type CreateWebhook,
} from '@/schemas/entities/webhook.schema'
import { EVENT_CREATE_WEBHOOK_CLICK, sendEvent } from '@/services/tracking.service'
import { createWebhook } from '@/services/webhook.service'
import { Button } from '@/shared/ui/base/button'
import CreditsBadge from '@/shared/ui/credits/credits-badge'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../base/select'
import { TestEventButton } from './test-event-button'

export function AddWebhookForm({
    onSuccess,
    searchId: currentSearchId,
    eventType,
    n_records,
    n_records_per_day,
}: {
    onSuccess: (id: number) => void
    eventType?: WebhookEventType
    searchId?: number
    n_records?: number
    n_records_per_day?: number
}) {
    const [defaultSearchId, setDefaultSearchId] = useState<number | undefined>(currentSearchId)
    const { searches } = useSavedSearches()
    const [currentTime] = useState<string>(new Date().toISOString())
    const form = useForm<CreateWebhook>({
        resolver: zodResolver(createWebhookSchema),
        defaultValues: {
            url: '',
            description: '',
            event_type: eventType,
            listening_start_time: currentTime,
            search_id: currentSearchId,
        },
    })
    const { mutate, isPending } = useMutation({
        mutationFn: (data: CreateWebhook) => createWebhook(data).then((webhook) => webhook.id),
        onSuccess,
    })
    const onSubmit = (data: CreateWebhook) => {
        sendEvent(EVENT_CREATE_WEBHOOK_CLICK)
        mutate(data)
    }

    const entityName = form.getValues('event_type') == 'job_new' ? 'jobs' : 'companies'

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 pb-10">
                <FormField
                    control={form.control}
                    name="event_type"
                    render={({ field }) => (
                        <FormItem>
                            <div>
                                <FormLabel>Events</FormLabel>
                                <p className="text-xs text-gray-500">Select the event the webhook will listen to.</p>
                            </div>
                            <FormControl>
                                <RadioGroup
                                    onValueChange={(value: WebhookEventType) => {
                                        field.onChange(value)
                                        form.setValue('search_id', -1)
                                        setDefaultSearchId(-1)
                                    }}
                                    defaultValue={field.value}
                                    className="flex flex-col space-y-1"
                                >
                                    {Object.entries(webhookLabels).map(([key, label]) => (
                                        <FormItem key={key} className="flex items-center space-x-3 space-y-0">
                                            <FormControl>
                                                <RadioGroupItem value={key} />
                                            </FormControl>
                                            <div className="flex flex-col">
                                                <FormLabel className="font-normal text-sm">{label}</FormLabel>
                                                <span className="text-xs text-gray-500">{key.replace('_', '.')}</span>
                                            </div>
                                        </FormItem>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="search_id"
                    render={({ field }) => (
                        <FormItem>
                            <div>
                                <FormLabel>Saved Search</FormLabel>
                                <p className="text-xs text-gray-500">
                                    This search's filters determine which records will trigger the webhook.
                                </p>
                            </div>
                            <FormControl>
                                <Select
                                    onValueChange={(value) => field.onChange(Number(value))}
                                    defaultValue={defaultSearchId?.toString() || ''}
                                >
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select a saved search" />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        {searches
                                            .filter((search) =>
                                                form.getValues('event_type') == 'job_new'
                                                    ? search.type == 'jobs'
                                                    : search.type == 'companies'
                                            )
                                            .map((search) => (
                                                <SelectItem
                                                    key={search.id}
                                                    value={search.id.toString()}
                                                    className="inline-flex items-center gap-2"
                                                >
                                                    {search.name} {search.id == currentSearchId ? ' (current)' : ''}
                                                </SelectItem>
                                            ))}
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="listening_start_time"
                    render={({ field }) => (
                        <FormItem>
                            <div>
                                <FormLabel>Choose where to start</FormLabel>
                                <p className="text-xs text-gray-500">
                                    Choose whether to start listening from this point forward or from the start of the
                                    search.
                                </p>
                            </div>
                            <FormControl>
                                <RadioGroup
                                    onValueChange={(value: string) =>
                                        value == '' ? field.onChange(null) : field.onChange(value)
                                    }
                                    defaultValue={currentTime}
                                    className="flex flex-col space-y-1"
                                >
                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value={currentTime} />
                                        </FormControl>
                                        <div className="flex flex-col">
                                            <FormLabel className="font-normal text-sm">
                                                From now on{' '}
                                                {n_records_per_day && n_records_per_day > 0 && (
                                                    <CreditsBadge
                                                        className="ml-1"
                                                        credits={n_records_per_day}
                                                        type="API"
                                                        size="sm"
                                                        frequency="day"
                                                        estimated={true}
                                                    />
                                                )}
                                            </FormLabel>
                                            <span className="text-xs text-gray-500">
                                                Only new {entityName} will be sent.
                                            </span>
                                        </div>
                                    </FormItem>
                                    <FormItem className="flex items-center space-x-3 space-y-0">
                                        <FormControl>
                                            <RadioGroupItem value={''} />
                                        </FormControl>
                                        <div className="flex flex-col">
                                            <FormLabel className="font-normal text-sm">
                                                All
                                                {n_records && n_records > 0 && (
                                                    <CreditsBadge
                                                        className="ml-1"
                                                        credits={n_records}
                                                        type="API"
                                                        size="sm"
                                                    />
                                                )}
                                                {n_records_per_day && n_records_per_day > 0 && (
                                                    <span className="text-xs ml-1">+</span>
                                                )}
                                                {n_records_per_day && n_records_per_day > 0 && (
                                                    <CreditsBadge
                                                        className="ml-1"
                                                        credits={n_records_per_day}
                                                        type="API"
                                                        size="sm"
                                                        frequency="day"
                                                        estimated={true}
                                                    />
                                                )}
                                            </FormLabel>
                                            <span className="text-xs text-gray-500">
                                                All current {entityName} and new {entityName} will be sent.
                                            </span>
                                        </div>
                                    </FormItem>
                                </RadioGroup>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    control={form.control}
                    name="url"
                    render={({ field }) => (
                        <FormItem>
                            <div>
                                <FormLabel>Webhook URL</FormLabel>
                                <p className="text-xs text-gray-500">
                                    Webhooks events will be sent as `POST` requests to this URL.
                                </p>
                            </div>
                            <FormControl>
                                <Input placeholder="https://" {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <div className="flex items-center space-x-2 justify-start">
                    <TestEventButton url={form.getValues('url')} event_type={form.getValues('event_type')} />
                </div>
                <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                        <FormItem>
                            <div>
                                <FormLabel>Description (optional)</FormLabel>
                                <p className="text-xs text-gray-500">
                                    Describe the webhook destination. This will help you identify it later.
                                </p>
                            </div>
                            <FormControl>
                                <Input placeholder="An optional description of the destination..." {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <div className="flex items-center space-x-2 justify-end">
                    <Button type="submit" isLoading={isPending}>
                        Create Webhook
                    </Button>
                </div>
            </form>
        </Form>
    )
}
