import { Progress } from '@/components/ui/base/progress'
import UpgradeButton from '@/components/ui/layout/components/upgrade_button'
import { CompanyList } from '@/schemas/entities/company-list.schema'
import { getTotalCredits, hasPaidSubscription } from '@/schemas/entities/plan'
import { UserWithTeam } from '@/schemas/entities/user'
import { getAllCompanyLists } from '@/services/company_list.service'
import { Card } from '@/shared/ui/base/card'
import CreditsIcon from '@/shared/ui/credits/credits-icon'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'

export default function CreditsUsage({ me }: { me: UserWithTeam }) {
    const { data: lists = [] } = useQuery<CompanyList[]>({
        queryKey: ['company-list'],
        queryFn: getAllCompanyLists,
        staleTime: HOUR_IN_MS,
    })

    return (
        <div>
            <h2 className="text-lg font-medium mb-4">Credits usage</h2>
            <div className="grid grid-cols-2 gap-2">
                <Card className="p-4">
                    <div className="bg-green-200 rounded-md w-10 h-10 flex justify-center items-center text-green-800 mb-2">
                        <CreditsIcon className="w-6 h-6" />
                    </div>
                    <h3 className="text-base font-medium mb-2">Company credits usage</h3>
                    <p className="text-xs text-gray-500 mb-4">
                        Company credits are charged when you reveal a company in the TheirStack UI (app.theirstack.com).
                    </p>
                    <p className="text-sm">
                        {' '}
                        {formatNumberWithComma(me.team.credits_used_current_period)} used of{' '}
                        {formatNumberWithComma(getTotalCredits(me))}{' '}
                        <Link
                            to={`/company-list/${lists.find((list) => list.name == 'Companies seen')?.id}`}
                            className="pl-2 underline"
                        >
                            review companies seen
                        </Link>
                    </p>
                    <div className="flex flex-row gap-2 items-center mb-2">
                        <Progress
                            className="h-4"
                            value={(me.team.credits_used_current_period / getTotalCredits(me)) * 100}
                        />
                        {!hasPaidSubscription(me) && <UpgradeButton variant="default" showIcon={false} size="sm" />}
                        {hasPaidSubscription(me) && (
                            <UpgradeButton
                                label="Buy more"
                                variant="default"
                                mode="one_time"
                                showIcon={false}
                                size="sm"
                            />
                        )}
                    </div>
                </Card>
                <Card className="p-4">
                    <div className="bg-blue-200 rounded-md w-10 h-10 flex justify-center items-center text-blue-800 mb-2">
                        <CreditsIcon className="w-6 h-6" />
                    </div>
                    <h3 className="text-base font-medium mb-2">API credits usage</h3>
                    <p className="text-xs text-gray-500 mb-4">
                        API credits are charged when you use the TheirStack API (api.theirstack.com) or webhooks to
                        search jobs, companies, and more.
                    </p>
                    <p className="text-sm">
                        {' '}
                        {formatNumberWithComma(me.team.api_credits_used_current_period)} used of{' '}
                        {formatNumberWithComma(me.team.api_credits)}{' '}
                        <Link to={`/settings/api`} className="pl-2 underline">
                            review requests
                        </Link>
                    </p>
                    <div className="flex flex-row gap-2 items-center mb-2">
                        <Progress
                            className="h-4"
                            value={(me.team.api_credits_used_current_period / me.team.api_credits) * 100}
                        />
                        {!hasPaidSubscription(me) && (
                            <UpgradeButton variant="default" showIcon={false} size="sm" defaultTab="api" />
                        )}
                        {hasPaidSubscription(me) && (
                            <UpgradeButton
                                label="Buy more"
                                variant="default"
                                mode="one_time"
                                showIcon={false}
                                size="sm"
                                defaultTab="api"
                            />
                        )}
                    </div>
                </Card>
            </div>
        </div>
    )
}
