import { NavigateToPage } from '@/components/ui/sheet/paginated-sheet/base/paginated-sheet'
import { WebhookDetails } from '@/components/ui/webhooks/webhook-details/webhook-details'
import { useSearchParams } from 'react-router-dom'
import { WEBHOOK_EDIT_PAGE_SLUG, WEBHOOKS_PAGE_SLUG } from '../../instances/api-webhook-sheet'

export function WebhookDetailsPage({ navigateToPage }: { navigateToPage: NavigateToPage }) {
    const [searchParams] = useSearchParams()
    const webhookId = Number(searchParams.get('id'))
    if (!webhookId) return null

    return (
        <WebhookDetails
            webhookId={webhookId}
            handleEdit={(id: number) => navigateToPage(WEBHOOK_EDIT_PAGE_SLUG, { id: id.toString() })}
            onArchiveSuccess={() => navigateToPage(WEBHOOKS_PAGE_SLUG, {})}
        />
    )
}
