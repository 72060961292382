import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/base/sheet'
import { WithTooltip } from '@/shared/ui/base/tooltip'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SheetHeader } from './paginated-sheet-header'

export type NavigateToPage = (pageId: string, params?: Record<string, string>) => void
export interface SheetPage {
    id: string
    title: string
    is_home?: boolean
    content: (props: { navigateToPage: NavigateToPage; params?: Record<string, string> }) => React.ReactNode
    children?: SheetPage[]
}

export function PaginatedSheet({
    trigger,
    pages,
    queryParam = 'view',
    className = '',
}: {
    trigger: React.ReactNode
    pages: SheetPage[]
    queryParam?: string
    className?: string
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const isOpen = searchParams.has(queryParam)

    const currentPageId = searchParams.get(queryParam)
    const isHome = !currentPageId || currentPageId === pages[0].id

    const findPage = useCallback((pages: SheetPage[], pageId: string): SheetPage | undefined => {
        for (const page of pages) {
            if (page.id === pageId) return page
            if (page.children) {
                const found = findPage(page.children, pageId)
                if (found) return found
            }
        }
        return undefined
    }, [])

    const currentPage = useMemo(() => {
        if (!currentPageId) return pages[0]
        return findPage(pages, currentPageId) || pages[0]
    }, [currentPageId, pages, findPage])

    const navigateToPage = (pageId: string, params?: Record<string, string>) => {
        const newParams = new URLSearchParams(searchParams)
        newParams.set(queryParam, pageId)
        if (params) {
            for (const [key, value] of Object.entries(params)) {
                newParams.set(key, value)
            }
        }
        setSearchParams(newParams)
    }

    const navigateHome = () => {
        const newParams = new URLSearchParams(searchParams)
        newParams.set(queryParam, pages[0].id)
        setSearchParams(newParams)
    }

    const onOpenChange = (open: boolean) => {
        const newParams = new URLSearchParams(searchParams)
        if (!open) {
            newParams.delete(queryParam)
        } else if (!newParams.has(queryParam)) {
            newParams.set(queryParam, pages[0].id)
        }
        setSearchParams(newParams)
    }

    return (
        <div className={className}>
            <Sheet open={isOpen} onOpenChange={onOpenChange}>
                <WithTooltip title="Pull this list with our API">
                    <SheetTrigger>{trigger}</SheetTrigger>
                </WithTooltip>
                <SheetContent className="w-full md:w-2/3 lg:w-3/5 xl:w-3/6 overflow-y-auto">
                    <div className="flex flex-col h-full">
                        <SheetHeader title={currentPage.title} showBackButton={!isHome} onBack={navigateHome} />
                        <currentPage.content navigateToPage={navigateToPage} />
                    </div>
                </SheetContent>
            </Sheet>
        </div>
    )
}
