import { useRevealCompany } from '@/components/hooks/company/use-reveal-company'
import { ConfirmRevealCompanyDialog } from '@/components/ui/dialog/confirm-reveal-company-dialog'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import OutOfCreditsDialog from '@/components/ui/dialog/out-of-credits-dialog'
import { FullCompany, TechnologyDetaislFound } from '@/schemas/entities/full-company.schema'
import { QueryNewJobSearch } from '@/schemas/entities/query-new-job-search.schema'
import { Company } from '@/shared/schemas/entity/company.schema'
import { Badge } from '@/shared/ui/base/badge'
import { Separator } from '@/shared/ui/base/separator'
import { formatDateStringToReadableDate } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { ExternalLink } from 'lucide-react'
import { useRef } from 'react'

export function HoverTechnologyCard({
    company,
    techdetails,
    updateCompany,
}: {
    company: FullCompany
    techdetails: TechnologyDetaislFound
    updateCompany: (company: FullCompany) => void
}) {
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const { revealCompany } = useRevealCompany({
        company,
        updateCompany,
        postRevealAction: (company) => window.open(getLink(company), '_blank'),
        showUpgradeDialog: () => outOfCreditsDialogRef.current?.open(),
    })

    const getLink = (company: Company) => {
        const search: QueryNewJobSearch = {
            name: 'Jobs using ' + techdetails.technology.name + ' posted by ' + company.name,
            auto_search: true,
            draft_mode: true,
            query: {
                company_name_or: [company.name],
                job_technology_slug_or: [techdetails.technology.slug],
                order_by: [
                    {
                        field: 'date_posted',
                        desc: true,
                    },
                ],
            },
        }
        return '/search/jobs/new?query=' + btoa(encodeURIComponent(JSON.stringify(search)))
    }

    // const link = getLink(company)

    return (
        <>
            <div className="flex gap-1 flex-col mb-2">
                <h2 className="text-lg">{techdetails.technology.name}'s usage</h2>
                <div className="flex gap-2 items-center text-gray-400">
                    <p className="text-sm  p-0 m-0">{techdetails.technology.category}</p>
                    <a
                        target="_blank"
                        href={'https://theirstack.com/en/category/' + techdetails.technology.category_slug}
                    >
                        {' '}
                        <ExternalLink className="h-3 w-3" />
                    </a>
                </div>
            </div>
            <Separator className="mb-4" />
            <div className="flex flex-col gap-2">
                <p>
                    The confidence of{' '}
                    <span className={`italic ${company.has_blurred_data ? 'blur-sm' : ''}`} translate="no">
                        {company.name}
                    </span>{' '}
                    using <span className="italic">{techdetails.technology.name}</span> is
                    <Badge
                        className={`text-xs ml-1 ${techdetails.confidence == 'high' ? 'text-primary border-primary' : techdetails.confidence == 'medium' ? 'text-orange-300 border-orange-300' : 'text-red-400 border-red-400'}`}
                        variant="outline"
                    >
                        {' '}
                        {techdetails.confidence}
                    </Badge>{' '}
                </p>

                {company.has_blurred_data && techdetails.first_date_found && techdetails.last_date_found && (
                    <ConfirmRevealCompanyDialog confirmAction={revealCompany} n_companies={1}>
                        <p>
                            The technology was mentioned in{' '}
                            <span className="underline cursor-pointer">
                                {formatNumberWithComma(techdetails.jobs)} job postings
                            </span>{' '}
                            between {formatDateStringToReadableDate(techdetails.first_date_found)} and{' '}
                            {formatDateStringToReadableDate(techdetails.last_date_found || '')}.
                        </p>
                    </ConfirmRevealCompanyDialog>
                )}
                {!company.has_blurred_data && techdetails.first_date_found && techdetails.last_date_found && (
                    <div className="inline">
                        <p>
                            The technology was mentioned in{' '}
                            {/* <a href={link} target="_blank" className="underline">
                                {formatNumberWithComma(techdetails.jobs)} job postings
                            </a>{' '} */}
                            {formatNumberWithComma(techdetails.jobs)} job postings between{' '}
                            {formatDateStringToReadableDate(techdetails.first_date_found)} and{' '}
                            {formatDateStringToReadableDate(techdetails.last_date_found || '')}.
                        </p>
                    </div>
                )}
                {techdetails.relative_occurrence_within_category && (
                    <p>
                        {(techdetails.relative_occurrence_within_category * 100).toPrecision(3)}% of{' '}
                        <span className={`italic ${company.has_blurred_data ? 'blur-sm' : ''}`}>{company.name}</span>{' '}
                        mentions in jobs for <span className="italic">{techdetails.technology.category}</span>{' '}
                        technologies were <span className="italic">{techdetails.technology.name}</span>.
                    </p>
                )}
            </div>

            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </>
    )
}
