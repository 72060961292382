import { useEffect } from 'react'
import APIRequestTable from './api-request-table/api-request-table'

function APIPage() {
    useEffect(() => {
        document.title = 'API Usage and Requests · Settings · TheirStack'
    }, [])

    return <APIRequestTable />
}

export default APIPage
