import { z } from 'zod'
import { invoiceConsumptionSchema } from './invoice'
import { UserWithTeam } from './user'

export const planSchema = z.object({
    name: z.string(),
    credits: z.number(),
    credits_used_current_period: z.number().nullable(),
    api_credits: z.number(),
    api_credits_used_current_period: z.number(),
    interval: z.string(),
    interval_count: z.number(),
    current_period_start: z.string().nullable(),
    current_period_end: z.string().nullable(),
    status: z.enum(['incomplete', 'active', 'refunded', 'canceled']),
    hosted_invoice_url: z.string().nullable().optional(),
    cancel_at_period_end: z.boolean().nullable(),
    recurring: z.boolean(),
    paid_at: z.string().nullable(),
    created_at: z.string().nullable(),
    subscription_id: z.string().nullable(),
    datasets: z.array(z.string()).nullable(),
    invoices: z.array(invoiceConsumptionSchema),
})
export type Plan = z.infer<typeof planSchema>

export const getLatestInvoiceConsumption = (plan: Plan) => {
    return plan.invoices?.sort((a, b) => b.created_at.getTime() - a.created_at.getTime())[0]
}

export const getActivePlan = (user: UserWithTeam) => {
    return user.team?.plans?.sort((a, b) => b.credits - a.credits).find((plan) => plan.status === 'active')
}

export const isFreePlan = (user: UserWithTeam): boolean => {
    return (user.team?.plans?.length ?? 1) === 1
}

export const hasPaidSubscription = (user: UserWithTeam): boolean => {
    return user.team?.plans?.some((plan) => plan.recurring === true && plan.name != 'Free') ?? false
}

export const getActivePaidSubscription = (user: UserWithTeam): z.infer<typeof planSchema> | undefined => {
    return user.team?.plans?.find((plan) => plan.status === 'active' && plan.recurring === true && plan.name != 'Free')
}

export const isCancelationAvailable = (plan: Plan): boolean => {
    return (
        ['active', 'incomplete'].includes(plan.status) &&
        plan.recurring === true &&
        plan.name != 'Free' &&
        plan.cancel_at_period_end != true
    )
}

export const getTotalCredits = (user: UserWithTeam) => {
    return user.team?.credits || 0
}

export const hasIcompletePlans = (user: UserWithTeam) => {
    return getIncompletePlans(user).length > 0
}

export const getIncompletePlans = (user: UserWithTeam) => {
    return user.team?.plans?.filter((plan) => isPlanIncomplete(plan)) || []
}

export const isPlanIncomplete = (plan: Plan) => {
    return plan.status === 'incomplete'
}

export const getCreditsFromOldInvoices = (
    plan: Plan,
    creditType: 'ui' | 'api'
): { used_credits: number; total_credits: number } => {
    const sortedInvoicesNotConsumed = plan.invoices
        .sort((a, b) => b.created_at.getTime() - a.created_at.getTime())
        .filter(
            (invoice) =>
                invoice.api_credits - invoice.used_api_credits > 0 || invoice.ui_credits - invoice.used_ui_credits > 0
        )

    if (creditType === 'ui') {
        const remaining_credits = sortedInvoicesNotConsumed.slice(1).reduce((acc, invoice) => {
            return acc + invoice.used_ui_credits
        }, 0)
        const total_credits = sortedInvoicesNotConsumed.slice(1).reduce((acc, invoice) => {
            return acc + invoice.ui_credits
        }, 0)
        return { used_credits: remaining_credits, total_credits }
    } else {
        const remaining_credits = sortedInvoicesNotConsumed.slice(1).reduce((acc, invoice) => {
            return acc + invoice.used_api_credits
        }, 0)
        const total_credits = sortedInvoicesNotConsumed.slice(1).reduce((acc, invoice) => {
            return acc + invoice.api_credits
        }, 0)
        return { used_credits: remaining_credits, total_credits }
    }
}
