import { useAutoSearchTable } from '@/components/hooks/data-table/use-auto-search-table'
import OutOfCreditsBanner from '@/components/ui/banners/out-of-credits-banner'
import { DataTable } from '@/components/ui/table/data-table'
import DataTableInitialState from '@/components/ui/table/data-table-initial-state'
import { DataTablePagination } from '@/components/ui/table/data-table-pagination'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { CompanySavedSearch } from '@/schemas/entities/saved-search.schema'
import { CompaniesSearchParams } from '@/schemas/request/companies-search-params.schema'
import { getCompaniesFromSearchParams } from '@/services/company.service'
import {
    companyColumns,
    hiddenCompanyColumns,
    updateSearchParamsWithDynamicColumns,
} from '../../components/columns-company'
import { useColumnsVisibilityBasedOnSearch } from '../../components/use-columns-visibility-based-on-search'
import { DataTableToolbar } from './data-table-toolbar'

interface DataTableProps {
    search: CompanySavedSearch
    setSearch: (_search: CompanySavedSearch) => void
}

export function DataTableWithCompanyToolbar({ search, setSearch }: DataTableProps) {
    const {
        table,
        metadata,
        columnVisibility,
        setColumnVisibility,
        columns,
        setColumns,
        searchedSP,
        isDataFetching,
        totalResults,
        isCurrentSearchSaved,
        isCurrentSearchSearched,
        rowSelection,
        setFiltersAsNotChanged,
        appliedFilters,
        addFilters,
        onChangeFilter,
        onRemoveFilter,
    } = useAutoSearchTable<CompaniesSearchParams, FullCompany>(
        search.body,
        companyColumns,
        hiddenCompanyColumns,
        updateSearchParamsWithDynamicColumns,
        getCompaniesFromSearchParams
    )
    useColumnsVisibilityBasedOnSearch(searchedSP, columnVisibility, columns, setColumnVisibility, setColumns)

    return (
        <>
            {metadata.truncated_results > 0 && <OutOfCreditsBanner hidden_results={metadata.truncated_results} />}
            <DataTableToolbar
                search={search}
                isDataFetching={isDataFetching}
                isCurrentSearchSearched={isCurrentSearchSearched}
                setSearch={setSearch}
                table={table}
                searchedSP={searchedSP}
                totalResults={totalResults}
                isCurrentSearchSave={isCurrentSearchSaved}
                rowSelection={rowSelection}
                setFiltersAsNotChanged={setFiltersAsNotChanged}
                appliedFilters={appliedFilters}
                addFilters={addFilters}
                onChangeFilter={onChangeFilter}
                onRemoveFilter={onRemoveFilter}
            />
            {!isCurrentSearchSearched && <DataTableInitialState search_type="companies" />}
            {isCurrentSearchSearched && (
                <>
                    <DataTable table={table} columns={companyColumns} isDataFetching={isDataFetching} />
                    <DataTablePagination table={table} totalResults={totalResults} />
                </>
            )}
        </>
    )
}
