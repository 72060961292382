import { useTeamMembers } from '@/queries/use-team-members.query'
import { Separator } from '@/shared/ui/base/separator'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/shared/ui/base/table'
import { useEffect } from 'react'
import InviteMemberButton from './components/invite-member-button'
import RemoveMembersButton from './components/remove-member-button'

function TeamPage() {
    const { team_members, invalidate } = useTeamMembers()

    useEffect(() => {
        document.title = 'Team · Settings · TheirStack'
    }, [])

    return (
        <div className="space-y-6">
            <div>
                <h3 className="text-lg font-medium">Team</h3>
                <p className="text-sm text-muted-foreground">Manage your team members</p>
            </div>
            <Separator />
            <div className="flex justify-between">
                <div>
                    {team_members && team_members.length <= 1 && (
                        <p className="text-sm text-muted-foreground align-middle">
                            You are the only member of your team.
                        </p>
                    )}
                </div>

                <InviteMemberButton onSuccess={invalidate} />
            </div>
            {team_members && team_members.length > 1 && (
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[300px]">Name</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {team_members.map((member) => (
                            <TableRow key={member.email}>
                                <TableCell>{member.first_name + ' ' + member.last_name}</TableCell>
                                <TableCell>{member.email}</TableCell>
                                <TableCell className="flex justify-end">
                                    <RemoveMembersButton member={member} invalidate={invalidate} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </div>
    )
}

export default TeamPage
