import { z } from 'zod'

export const webhookExecutionStatus = z.enum(['IN_PROGRESS', 'SUCCESS', 'FAILED'])

export type WebhookExecutionStatus = z.infer<typeof webhookExecutionStatus>

export const webhookExecutionSchema = z.object({
    id: z.number(),
    webhook_id: z.number(),
    job_id: z.number().nullable(),
    company_name: z.string().nullable(),
    triggered_at: z.string(),
    status: webhookExecutionStatus,
    created_at: z.string(),
    updated_at: z.string(),
})

export type WebhookExecution = z.infer<typeof webhookExecutionSchema>
