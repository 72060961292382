import { Button } from '@/shared/ui/base/button'
import { Table } from '@tanstack/react-table'
import { DownloadIcon } from 'lucide-react'
import { WithTooltip } from '../../../../../shared/ui/base/tooltip'

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/shared/ui/base/dialog'
import { DialogTrigger } from '@radix-ui/react-dialog'

import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import OutOfCreditsDialog from '@/components/ui/dialog/out-of-credits-dialog'
import { EVENT_EXPORT_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { useRef, useState } from 'react'
import { DataToExport, ExportDialogContent } from './export-dialog'

export default function ExportButton<R>({
    recordName,
    reportName,
    table,
    isDataFetching,
    fromRecordToCSVLines,
    isRowBlurred,
    getUniqueCompanyId,
    showExportToWebhook = false,
}: {
    recordName: string
    reportName: string
    isDataFetching: boolean
    table: Table<R>
    fromRecordToCSVLines: (records: R[]) => DataToExport
    isRowBlurred: (row: R) => boolean
    getUniqueCompanyId: (row: R) => string
    showExportToWebhook?: boolean
}) {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const isDisabled =
        isDataFetching || table.getRowModel().rows.length == 0 || (table.options?.meta?.getTotalResults() || 0) <= 0
    const button = (
        <Button className="text-sm font-normal" variant="ghost" size="sm" disabled={isDisabled}>
            <DownloadIcon className="h-4 w-4 md:mr-2" />
            <p className="hidden lg:inline">Export</p>
        </Button>
    )
    return (
        <>
            {isDisabled && (
                <WithTooltip
                    title="Export"
                    message={
                        isDataFetching || table.options?.meta?.getTotalResults() || 0
                            ? 'Wait until the data is loaded'
                            : 'No data to export'
                    }
                >
                    {button}
                </WithTooltip>
            )}
            {!isDisabled && (
                <>
                    <Dialog
                        open={isDialogOpen}
                        onOpenChange={(open) => {
                            if (open) {
                                sendEvent(EVENT_EXPORT_MODAL_VIEW, { type: recordName })
                                setIsDialogOpen(true)
                            }
                        }}
                    >
                        <WithTooltip title="Export">
                            <DialogTrigger asChild>{button}</DialogTrigger>
                        </WithTooltip>
                        <DialogContent
                            className="max-h-full maw-w-full sm:max-w-[600px] overflow-y-auto"
                            allowToClose={false}
                        >
                            <DialogHeader className="mb-2">
                                <DialogTitle>Export {recordName}</DialogTitle>
                            </DialogHeader>
                            <ExportDialogContent
                                table={table}
                                recordName={recordName}
                                reportName={reportName}
                                closeDialog={() => setIsDialogOpen(false)}
                                openOutOfCreditsDialog={() => outOfCreditsDialogRef.current?.open()}
                                fromRecordToCSVLines={fromRecordToCSVLines}
                                isRowBlurred={isRowBlurred}
                                getUniqueCompanyId={getUniqueCompanyId}
                                showExportToWebhook={showExportToWebhook}
                            />
                        </DialogContent>
                    </Dialog>
                    <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
                </>
            )}
        </>
    )
}
