import { useMe } from '@/components/hooks/use-me'
import { useToast } from '@/components/ui/base/use-toast'
import { Webhook } from '@/schemas/entities/webhook.schema'
import { archiveWebhook, updateWebhookStatus } from '@/services/webhook.service'
import { Button } from '@/shared/ui/base/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/shared/ui/base/dropdown-menu'
import { useQueryClient } from '@tanstack/react-query'
import { Archive, Copy, EllipsisVertical, ExternalLink, Pencil, Search, Target, ToggleRight } from 'lucide-react'
import { addMiddleEllipsis } from './webhook-details'
import { WebhookLogo } from './webhook-logo'
import { WebhookStatus } from './webhook-status'

export function WebhookCard({
    webhook,
    onArchiveSuccess,
    handleEdit,
}: {
    webhook: Webhook
    onArchiveSuccess: () => void
    handleEdit: (webhookId: number) => void
}) {
    const { me } = useMe()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const handleUpdateStatus = async (webhookId: number, isActive: boolean) => {
        try {
            await updateWebhookStatus(webhookId, isActive)
            queryClient.invalidateQueries({ queryKey: ['webhook', webhookId] })
            toast({
                title: `Webhook ${isActive ? 'enabled' : 'disabled'}`,
                description: `The webhook has been ${isActive ? 'enabled' : 'disabled'}.`,
            })
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update webhook status. Please try again.',
                variant: 'destructive',
            })
        }
    }

    const handleArchive = async (webhookId: number) => {
        try {
            await archiveWebhook(webhookId).then(() => {
                onArchiveSuccess()
            })
            queryClient.invalidateQueries({ queryKey: ['webhook', webhookId] })
            toast({
                title: `Webhook archived`,
                description: `The webhook has been archived.`,
            })
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to archive webhook. Please try again.',
                variant: 'destructive',
            })
        }
    }

    return (
        <>
            <div className="flex justify-between items-center gap-2">
                <div className="flex items-center gap-2 w-fit">
                    <WebhookLogo url={webhook.url} size={14} />
                    <div className="flex flex-col items-start gap-0.5 w-fit ">
                        <div className="flex items-center gap-0.5">
                            <h2 className="truncate w-fit text-ellipsis ">{addMiddleEllipsis(webhook.url)}</h2>
                            <WebhookStatus is_active={webhook.is_active} />
                        </div>

                        <p className="text-xs text-gray-500 inline-flex items-center">
                            {' '}
                            <Target className="h-3 w-3 mr-1" strokeWidth={2} />
                            {webhook.description ? webhook.description : 'No description'}
                        </p>
                        <a
                            href={
                                webhook.event_type === 'job_new'
                                    ? `/search/jobs/${webhook.search_id}`
                                    : `/search/companies/${webhook.search_id}`
                            }
                            target="_blank"
                            className="text-xs text-gray-500 inline-flex items-center whitespace-nowrap text-ellipsis hover:underline"
                        >
                            {' '}
                            <Search className="h-3 w-3 mr-1" strokeWidth={2} />
                            {webhook.search_name}
                        </a>
                    </div>
                </div>

                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" size="icon">
                            <EllipsisVertical className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[200px]">
                        <DropdownMenuItem
                            onClick={() => {
                                navigator.clipboard.writeText(webhook.url)
                                toast({
                                    title: 'URL copied to clipboard',
                                    description: 'You can now paste it anywhere.',
                                })
                            }}
                        >
                            <Copy className="h-4 w-4 mr-2" /> Copy Webhook URL
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => window.open(`/search/jobs/${webhook.search_id}`, '_blank')}>
                            <ExternalLink className="h-4 w-4 mr-2" /> See search
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleUpdateStatus(webhook.id, !webhook.is_active)}>
                            <ToggleRight className="h-4 w-4 mr-2" /> {webhook.is_active ? 'Disable' : 'Enable'}
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleEdit(webhook.id)}>
                            <Pencil className="h-4 w-4 mr-2" /> Edit
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={() => handleArchive(webhook.id)}
                            disabled={!(me && (me.id == webhook.user_id || me.is_superuser == true))}
                        >
                            <Archive className="h-4 w-4 mr-2" /> Archive
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </>
    )
}
