import { useToast } from '@/components/ui/base/use-toast'
import { ConfirmActionDialog } from '@/components/ui/dialog/confirm-action-dialog'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import { getLatestInvoiceConsumption, Plan } from '@/schemas/entities/plan'
import { deleteInvoiceConsumption as removeConsumption } from '@/services/billing.service'
import { Button } from '@/shared/ui/base/button'
import { EraserIcon } from 'lucide-react'
import { useRef } from 'react'

export default function AdminActions({ plan }: { plan: Plan }) {
    const confirmDeleteDialogRef = useRef<OpenDialogHandle>(null)
    const { toast } = useToast()
    return (
        <>
            <Button variant="destructive" size="icon" onClick={() => confirmDeleteDialogRef.current?.open()}>
                <EraserIcon className="w-4 h-4" />
            </Button>
            <ConfirmActionDialog
                ref={confirmDeleteDialogRef}
                title={`Are you sure you want to remove consumption for invoice ${getLatestInvoiceConsumption(plan)?.id}?`}
                description={`This action cannot be undone and will remove ${getLatestInvoiceConsumption(plan)?.used_api_credits} API credits and ${getLatestInvoiceConsumption(plan)?.used_ui_credits} company credits from this invoice.`}
                confirmAction={() => {
                    removeConsumption(getLatestInvoiceConsumption(plan)?.id ?? '')
                        .then(() => {
                            toast({ title: 'Consumption has been removed' })
                            confirmDeleteDialogRef.current?.close()
                        })
                        .catch(() => {
                            toast({ title: 'Failed to remove consumption', variant: 'destructive' })
                            confirmDeleteDialogRef.current?.close()
                        })
                }}
                cancelAction={() => confirmDeleteDialogRef.current?.close()}
                actionText="Remove consumption"
                actionVariant="destructive"
            />
        </>
    )
}
