import { NavigateToPage } from '@/components/ui/sheet/paginated-sheet/base/paginated-sheet'
import { AddWebhookForm } from '@/components/ui/webhooks/add-webhook-form'
import { WebhookEventType } from '@/schemas/entities/webhook.schema'
import { WEBHOOK_DETAILS_PAGE_SLUG } from '../../instances/api-webhook-sheet'

interface NewWebhookProps {
    navigateToPage: NavigateToPage
    eventType?: WebhookEventType
    searchId?: number
    n_records?: number
    n_records_per_day?: number
}

export function NewWebhook({ navigateToPage, eventType, searchId, n_records, n_records_per_day }: NewWebhookProps) {
    return (
        <AddWebhookForm
            searchId={searchId}
            eventType={eventType}
            onSuccess={(id) => navigateToPage(WEBHOOK_DETAILS_PAGE_SLUG, { id: id.toString() })}
            n_records={n_records}
            n_records_per_day={n_records_per_day}
        />
    )
}
