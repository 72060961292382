import { Webhook } from '@/schemas/entities/webhook.schema'
import { getAllWebhooks } from '@/services/webhook.service'
import { useQuery } from '@tanstack/react-query'

export function useWebhooks() {
    const { data: webhooks = [], isLoading } = useQuery<Webhook[]>({
        queryKey: ['webhooks'],
        queryFn: getAllWebhooks,
    })
    return { webhooks, isLoading }
}
