import { z } from 'zod'

export const webhookEventType = z.enum(['job_new', 'company_new'])

export type WebhookEventType = z.infer<typeof webhookEventType>

export const webhookLabels: { [key in WebhookEventType]: string } = {
    job_new: 'New Job',
    company_new: 'New Company',
}

export const webhookSchema = z.object({
    id: z.number(),
    url: z.string().url('Must be a valid URL'),
    user_id: z.number(),
    search_id: z.number(),
    event_type: webhookEventType,
    search_name: z.string(),
    description: z.string().optional(),
    is_active: z.boolean().default(true),
    created_at: z.string(),
    updated_at: z.string(),
    listening_start_time: z.string().optional().nullable(),
})

export type Webhook = z.infer<typeof webhookSchema>

export const createWebhookSchema = z.object({
    url: z.string().url('Must be a valid URL'),
    search_id: z.number().refine((id) => id > 0, {
        message: 'Please select a valid search.',
    }),
    description: z.string().optional(),
    event_type: webhookEventType,
    listening_start_time: z.string().optional().nullable(),
})

export type CreateWebhook = z.infer<typeof createWebhookSchema>

export const editWebhookSchema = z.object({
    url: z.string().url('Must be a valid URL'),
    search_id: z.number(),
    description: z.string().optional(),
    event_type: webhookEventType,
})

export type EditWebhook = z.infer<typeof editWebhookSchema>

export const testWebhookSchema = z.object({
    url: z.string().url('Must be a valid URL'),
})

export type TestWebhook = z.infer<typeof testWebhookSchema>

export const updateWebhookStatusSchema = z.object({
    is_active: z.boolean(),
})

export type UpdateWebhookStatus = z.infer<typeof updateWebhookStatusSchema>
