import { jwtDecode } from 'jwt-decode'

const LOCALSTORAGE_TOKEN = 'ts-token'
const LOCALSTORAGE_EXPIRATION_TOKEN = 'ts-token-expiration'
const LOCALSTORAGE_TOKEN_IMPERSONATE = 'ts-impersonate-token'
const LOCALSTORAGE_EMAIL_IMPERSONATE = 'ts-impersonate-email'

export function getToken() {
    return localStorage.getItem(LOCALSTORAGE_TOKEN)
}

export function getImpersonatingToken() {
    return localStorage.getItem(LOCALSTORAGE_TOKEN_IMPERSONATE)
}

export const getImpersonatingEmail = () => {
    return localStorage.getItem(LOCALSTORAGE_EMAIL_IMPERSONATE)
}

export const impersonate = (email: string, token: string) => {
    localStorage.setItem(LOCALSTORAGE_TOKEN_IMPERSONATE, token)
    localStorage.setItem(LOCALSTORAGE_EMAIL_IMPERSONATE, email)
}
export const stopImpersonating = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN_IMPERSONATE)
    localStorage.removeItem(LOCALSTORAGE_EMAIL_IMPERSONATE)
}

export const cleanLocalStorage = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN)
    localStorage.removeItem(LOCALSTORAGE_EXPIRATION_TOKEN)
    localStorage.removeItem(LOCALSTORAGE_TOKEN_IMPERSONATE)
    localStorage.removeItem(LOCALSTORAGE_EMAIL_IMPERSONATE)
}

export const setTokenAndExpirationDate = (token: string) => {
    cleanLocalStorage()
    const exp = jwtDecode(token).exp as number
    const expirationDate = new Date(exp * 1000)

    localStorage.setItem(LOCALSTORAGE_EXPIRATION_TOKEN, expirationDate.toString())
    localStorage.setItem(LOCALSTORAGE_TOKEN, token)
}

export const isTokenValid = () => {
    const token = getToken()
    const expirationDate = getTokenExpirationDate()
    if (token && expirationDate && expirationDate > new Date()) {
        return true
    }
    return false
}

export const getTokenExpirationDate = () => {
    const expirationDate = localStorage.getItem(LOCALSTORAGE_EXPIRATION_TOKEN)
    if (expirationDate) {
        return new Date(expirationDate)
    }
    return null
}
