import { WebhookList } from '@/components/ui/webhooks/webhook-list'
import { Button } from '@/shared/ui/base/button'
import { Separator } from '@/shared/ui/base/separator'
import { Plus } from 'lucide-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function WebhooksPage() {
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Webhooks · Settings · TheirStack'
    }, [])

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <div>
                    <h3 className="text-lg font-medium">Webhooks</h3>
                    <p className="text-sm text-muted-foreground">Manage your webhooks</p>
                </div>
                <Button
                    onClick={() => {
                        navigate('/settings/webhooks/new')
                    }}
                >
                    <Plus className="h-4 w-4 mr-2" />
                    New Webhook
                </Button>
            </div>

            <Separator />
            <WebhookList
                handleView={(id) => {
                    navigate(`/settings/webhooks/${id}`)
                }}
            />
        </div>
    )
}
