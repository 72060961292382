import { WebhookDetails } from '@/components/ui/webhooks/webhook-details/webhook-details'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export function WebhookDetailsPage() {
    const { id: webhookId } = useParams<{ id: string }>()
    const navigate = useNavigate()
    useEffect(() => {
        document.title = 'Webhook Details · Settings · TheirStack'
    }, [])

    return (
        <div className="space-y-6">
            <WebhookDetails
                webhookId={Number(webhookId)}
                handleEdit={(id) => navigate(`/settings/webhooks/${id}/edit`)}
                onArchiveSuccess={() => navigate('/settings/webhooks')}
            />
        </div>
    )
}
