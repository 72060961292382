import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts'

import { useWebhookExecutionCountPerDay } from '@/components/hooks/use-webhook-execution-count'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/base/chart'
import { Webhook } from '@/schemas/entities/webhook.schema'
import { Card, CardContent } from '@/shared/ui/base/card'
import { formatDateToStringMonthAndDay, getTodayEnd } from '@/shared/utils/date-utils'
import formatNumberToNDigits from '@/shared/utils/number-utils'
import { format } from 'date-fns'

export const description = 'An interactive bar chart'

const chartConfig = {
    events: {
        label: 'Events',
        color: 'hsl(var(--chart-2))',
    },
} satisfies ChartConfig

export function WebhookExecutionsChart({ webhook }: { webhook: Webhook }) {
    const { webhookExecutionCount, isLoading } = useWebhookExecutionCountPerDay(
        new Date(webhook?.created_at || ''),
        getTodayEnd(),
        webhook?.id
    )
    const chart_data = webhookExecutionCount.map((item) => ({
        date: format(new Date(item.period_start), 'yyyy-MM-dd'),
        events: item.number_of_executions,
    }))

    return (
        <>
            {isLoading ? (
                <WebhookExecutionsChartSkeleton />
            ) : webhookExecutionCount.length > 0 ? (
                <Card>
                    <CardContent className="px-2 sm:p-4">
                        <ChartContainer config={chartConfig} className="aspect-auto h-[150px] w-full">
                            <BarChart
                                accessibilityLayer
                                data={chart_data}
                                margin={{
                                    left: 12,
                                    right: 12,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="date"
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={8}
                                    minTickGap={32}
                                    tickFormatter={(date) => formatDateToStringMonthAndDay(date)}
                                />
                                <YAxis
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={(value) => formatNumberToNDigits(value, 2)}
                                    tickMargin={2}
                                />

                                <ChartTooltip
                                    content={
                                        <ChartTooltipContent
                                            className="w-[150px]"
                                            nameKey="events"
                                            labelFormatter={(date) => formatDateToStringMonthAndDay(date, 'numeric')}
                                        />
                                    }
                                />

                                <Bar stackId="a" dataKey="events" fill="hsl(var(--chart-2))" />
                            </BarChart>
                        </ChartContainer>
                    </CardContent>
                </Card>
            ) : null}
        </>
    )
}

export function WebhookExecutionsChartSkeleton() {
    return <div className="h-[150px] bg-gray-200 rounded animate-pulse" />
}
