'use client'

import { addDays, endOfDay, format, startOfDay } from 'date-fns'
import { CalendarIcon, ChevronDownIcon } from 'lucide-react'
import { DateRange } from 'react-day-picker'

import { Calendar } from '@/components/ui/base/calendar'
import { cn } from '@/lib/utils'
import { Button } from '@/shared/ui/base/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/ui/base/popover'
import { isSameDateRange } from '@/utils/date-utils'
import { useState } from 'react'

export const OPTIONS_DATE_RANGE = [
    {
        label: 'Last 7 days',
        value: {
            from: startOfDay(addDays(new Date(), -7)),
            to: endOfDay(new Date()),
        },
    },
    {
        label: 'Last 30 days',
        value: {
            from: startOfDay(addDays(new Date(), -30)),
            to: endOfDay(new Date()),
        },
    },
    {
        label: 'Last 60 days',
        value: {
            from: startOfDay(addDays(new Date(), -60)),
            to: endOfDay(new Date()),
        },
    },
    {
        label: 'Last 90 days',
        value: {
            from: startOfDay(addDays(new Date(), -90)),
            to: endOfDay(new Date()),
        },
    },
]

export function DatePickerWithRange({
    onChangeDate,
    className = '',
}: {
    onChangeDate: (_date: DateRange | undefined) => void
    className?: string
}) {
    const [selectedDate, setSelectedDate] = useState<DateRange | undefined>(OPTIONS_DATE_RANGE[0].value)

    const handleOnChangeDate = (date: DateRange | undefined) => {
        if (date?.to && date?.from) {
            const isPresetRange = OPTIONS_DATE_RANGE.some((option) => isSameDateRange(option.value, date))
            if (!isPresetRange) {
                date.from = startOfDay(date.from)
                date.to = endOfDay(date.to)
            }
        }
        setSelectedDate(date)
        onChangeDate(date)
    }

    const getLabel = (date: DateRange | undefined) => {
        if (!date) return 'Pick a date'
        const matchingOption = OPTIONS_DATE_RANGE.find((option) => isSameDateRange(option.value, date))
        if (matchingOption) return matchingOption.label
        return `${format(date.from as Date, 'LLL dd, y')} - ${format(date.to as Date, 'LLL dd, y')}`
    }

    return (
        <div className={cn('grid gap-2', className)}>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={'outline'}
                        className={cn(
                            'w-[280px] justify-start text-left font-normal',
                            !selectedDate && 'text-muted-foreground'
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        <span className="flex-1">{getLabel(selectedDate)}</span>
                        <ChevronDownIcon className="h-4 w-4" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <div className="flex flex-row">
                        <Calendar
                            initialFocus
                            mode="range"
                            defaultMonth={selectedDate?.from}
                            selected={selectedDate}
                            onSelect={handleOnChangeDate}
                            numberOfMonths={2}
                        />
                        <div className="flex flex-col justify-start gap-2 p-3">
                            {OPTIONS_DATE_RANGE.map((option) => (
                                <Button
                                    key={option.label}
                                    variant="ghost"
                                    className="justify-start"
                                    onClick={() => handleOnChangeDate(option.value)}
                                >
                                    {option.label}
                                </Button>
                            ))}
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    )
}
