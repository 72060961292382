import { useWebhook } from '@/components/hooks/use-webhook'
import { getWebhookExecutions } from '@/services/webhook.service'
import { Button } from '@/shared/ui/base/button'
import { formatDateTimeStringToReadableDateTime, timeAgo } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons'
import { useQuery } from '@tanstack/react-query'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    CircleCheck,
    CircleDot,
    CircleX,
    Plus,
    Webhook as WebhookIcon,
} from 'lucide-react'
import { useState } from 'react'
import { AnimatedEmptyState } from '../../animated-emty-state'
import { TestEventButton } from '../test-event-button'

export function WebhookEvents({ webhookId, count }: { webhookId: number; count: number }) {
    const limit = 10
    const [offset, setOffset] = useState(0)
    const { webhook } = useWebhook(webhookId)
    const { data, isLoading } = useQuery({
        queryKey: ['webhook-executions', webhookId, offset],
        queryFn: () => getWebhookExecutions(webhookId, offset, limit),
    })

    return (
        <>
            {isLoading && <WebhookEventsSkeleton />}

            {data && data.length === 0 && webhook && (
                <AnimatedEmptyState
                    title="No events sent yet"
                    description={
                        webhook.listening_start_time
                            ? `All new jobs discovered after ${formatDateTimeStringToReadableDateTime(
                                  webhook.listening_start_time
                              )} will be sent to your webhook url. This could take a few minutes.`
                            : 'This could take a few minutes.'
                    }
                    className="md:min-h-[400px]"
                    cardContent={
                        <>
                            <WebhookIcon className="size-4 text-neutral-700" />
                            <div className="h-2.5 w-24 min-w-0 rounded-sm bg-neutral-200" />
                            <div className="flex  grow items-center justify-end gap-1.5 text-gray-500">
                                <Plus className="size-3.5 text-gray-500" />
                            </div>
                        </>
                    }
                    addButton={<TestEventButton url={webhook?.url || ''} event_type={webhook?.event_type} />}
                />
            )}
            {data && data.length > 0 && (
                <div>
                    <div className="flex flex-col gap-2">
                        {data &&
                            data.map((execution) => (
                                <div
                                    className="border p-2 flex justify-start gap-2 border-gray-200 rounded-md items-center"
                                    key={execution.id}
                                >
                                    {execution.status === 'SUCCESS' && (
                                        <CircleCheck className="h-4 w-4 text-green-600" />
                                    )}
                                    {execution.status === 'IN_PROGRESS' && (
                                        <CircleDot className="h-4 w-4 text-yellow-600" />
                                    )}
                                    {execution.status === 'FAILED' && <CircleX className="h-4 w-4 text-red-600" />}
                                    {webhook?.event_type === 'job_new' && (
                                        <p className="text-sm">
                                            job.new <span className="text-gray-500">(job_id :{execution.job_id})</span>
                                        </p>
                                    )}
                                    {webhook?.event_type === 'company_new' && (
                                        <p className="text-sm w-full max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                            company.new <span className="text-gray-500">{execution.company_name}</span>
                                        </p>
                                    )}
                                    <p className="text-sm text-gray-500 ml-auto whitespace-nowrap">
                                        {timeAgo(execution.triggered_at)}
                                    </p>
                                </div>
                            ))}
                    </div>
                    <div className="flex justify-between mt-4 items-center">
                        <p className="text-sm text-gray-500">
                            Showing {offset + 1} - {offset + data.length} of {formatNumberWithComma(count)} results
                        </p>
                        <div className="flex gap-2">
                            <Button
                                variant="outline"
                                className="hidden h-8 w-8 p-0 lg:flex"
                                onClick={() => setOffset(0)}
                                disabled={offset === 0}
                            >
                                <span className="sr-only">Go to first page</span>
                                <DoubleArrowLeftIcon className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="h-8 w-8 p-0"
                                disabled={offset === 0}
                                onClick={() => setOffset(offset - limit)}
                            >
                                <span className="sr-only">Go to previous page</span>
                                <ChevronLeftIcon className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="h-8 w-8 p-0"
                                disabled={data?.length !== limit || count === 0}
                                onClick={() => setOffset(offset + limit)}
                            >
                                <span className="sr-only">Go to next page</span>
                                <ChevronRightIcon className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="outline"
                                className="hidden h-8 w-8 p-0 lg:flex"
                                onClick={() => count !== undefined && setOffset(Math.floor(count / limit) * limit)}
                                disabled={
                                    count === undefined || count === 0 || offset === Math.floor(count / limit) * limit
                                }
                            >
                                <span className="sr-only">Go to last page</span>
                                <DoubleArrowRightIcon className="h-4 w-4" />
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export function WebhookEventsSkeleton() {
    return (
        <div className="flex flex-col gap-2 ">
            {[...Array(10)].map((_, i) => (
                <div className="h-10 bg-gray-200 rounded animate-pulse" key={i} />
            ))}
        </div>
    )
}
