import { useWebhook } from '@/components/hooks/use-webhook'
import { getWebhookExecutionCount } from '@/services/webhook.service'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { useQuery } from '@tanstack/react-query'
import { WebhookCard } from './webhook-card'
import { WebhookEvents, WebhookEventsSkeleton } from './webhook-events'
import { WebhookExecutionsChart, WebhookExecutionsChartSkeleton } from './webhook-executions-chart'

interface WebhookDetailsProps {
    webhookId: number
    handleEdit: (webhookId: number) => void
    onArchiveSuccess: () => void
}

export function addMiddleEllipsis(url: string, maxLength: number = 20) {
    const firstPart = url.slice(0, maxLength)
    const lastPart = url.slice(-maxLength)
    return url.length > maxLength ? `${firstPart}...${lastPart}` : url
}

export function WebhookDetails({ webhookId, handleEdit, onArchiveSuccess }: WebhookDetailsProps) {
    const { webhook, isLoading } = useWebhook(webhookId)
    const { data: count } = useQuery({
        queryKey: ['webhook-execution-count', webhookId],
        queryFn: () => getWebhookExecutionCount(webhookId),
    })

    return (
        <div className="flex flex-col gap-8">
            {isLoading && <div className="h-16 bg-gray-200 rounded animate-pulse" />}
            {!isLoading && !webhook && <div>Webhook not found</div>}
            {!isLoading && webhook && (
                <WebhookCard webhook={webhook} onArchiveSuccess={onArchiveSuccess} handleEdit={handleEdit} />
            )}
            <div className="flex flex-col gap-2">
                <h2 className="text-lg font-medium">Events {count ? `(${formatNumberWithComma(count)})` : ''}</h2>
                {webhook && count !== undefined && count > 0 ? (
                    <WebhookExecutionsChart webhook={webhook} />
                ) : webhook && count === 0 ? null : (
                    <WebhookExecutionsChartSkeleton />
                )}

                {webhookId && count !== undefined ? (
                    <WebhookEvents webhookId={webhookId} count={count} />
                ) : (
                    <WebhookEventsSkeleton />
                )}
            </div>
        </div>
    )
}
