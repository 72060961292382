import { useWebhook } from '@/components/hooks/use-webhook'
import { NavigateToPage } from '@/components/ui/sheet/paginated-sheet/base/paginated-sheet'
import { EditWebhookForm } from '@/components/ui/webhooks/edit-webhook-form'
import { Spinner } from '@/shared/ui/base/spinner'
import { useSearchParams } from 'react-router-dom'

export function EditWebhook({ navigateToPage }: { navigateToPage: NavigateToPage }) {
    const [searchParams] = useSearchParams()
    const { webhook, isLoading } = useWebhook(Number(searchParams.get('id')))

    if (isLoading)
        return (
            <div className="flex justify-center items-center ">
                <Spinner />
            </div>
        )
    if (!webhook) return null

    return (
        <EditWebhookForm
            webhook={webhook}
            onCancel={() => navigateToPage('webhooks')}
            onSuccess={() => navigateToPage('webhooks')}
        />
    )
}
