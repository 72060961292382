import { ColumnDef } from '@tanstack/react-table'

import APICurlButton from '@/components/ui/action-get-api-curl'
import { DataTableColumnHeader } from '@/components/ui/table/data-table-column-header'
import { extractPathFromTheirStackAPIURL } from '@/lib/http-utils'
import { Request } from '@/schemas/entities/recent-search.schema'
import { getCompaniesCURL } from '@/services/company.service'
import { getJobsCURL } from '@/services/job.service'
import { getTechDetailsCURL } from '@/services/tech-stack.service'
import { WithTooltip } from '@/shared/ui/base/tooltip'
import CreditsBadge from '@/shared/ui/credits/credits-badge'
import CreditsIcon from '@/shared/ui/credits/credits-icon'
import { formatDateTimeStringToReadableDateTime } from '@/shared/utils/date-utils'
import { CheckIcon, TimerIcon, XIcon } from 'lucide-react'

export const hiddenColumns = { user: false }

export const columns: ColumnDef<Request>[] = [
    {
        accessorKey: 'id',
        meta: {
            name: () => 'ID',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
        cell: ({ row }) => {
            return <>{row.original && row.original.start_datetime && <p className="w-16">{row.original.id}</p>}</>
        },
        enableSorting: false,
    },
    {
        accessorKey: 'start_datetime',
        meta: {
            name: () => 'Date',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Date" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original && row.original.start_datetime && (
                        <p className="w-44">{formatDateTimeStringToReadableDateTime(row.original.start_datetime)}</p>
                    )}
                </>
            )
        },
        enableSorting: false,
    },

    {
        accessorKey: 'url',
        meta: {
            name: () => 'Endpoint',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Endpoint" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original && row.original.start_datetime && (
                        <a
                            href={`https://api.theirstack.com`}
                            target="_blank"
                            rel="noreferrer"
                            className=" hover:underline"
                        >
                            <span className="mr-1 text-green-500">POST</span>
                            {extractPathFromTheirStackAPIURL(row.original.url)}
                        </a>
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },

    {
        accessorKey: 'user',
        meta: {
            name: () => 'User',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="User" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original && (
                        <div className="flex flex-col items-start gap-0.5">
                            <p className="text-xs">
                                {row.original.user.first_name} {row.original.user.last_name}
                            </p>
                            <p className="text-xs text-muted-foreground">{row.original.user.email}</p>
                        </div>
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },

    {
        accessorKey: 'status',
        meta: {
            name: () => 'Status',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        cell: ({ row }) => {
            const statusCode = row.original?.status_code || row.original?.completed ? 200 : 408
            return (
                <>
                    {statusCode === 200 ? (
                        <WithTooltip message="The request was successful">
                            <p className="flex items-center gap-1 whitespace-nowrap text-green-500 p-1 rounded-md border text-xs cursor-pointer">
                                <CheckIcon className="w-4 h-4" /> Ok
                            </p>
                        </WithTooltip>
                    ) : statusCode === 408 ? (
                        <WithTooltip message="The request timed out">
                            <p className="flex items-center gap-1 whitespace-nowrap text-yellow-500 p-1 rounded-md border text-xs cursor-pointer">
                                <TimerIcon className="w-4 h-4" /> Timeout
                            </p>
                        </WithTooltip>
                    ) : statusCode === 403 ? (
                        <WithTooltip message="There are no API credits left to perform this request">
                            <p className="flex items-center gap-1 whitespace-nowrap text-red-500 p-1 rounded-md border text-xs cursor-pointer">
                                <CreditsIcon className="w-4 h-4" /> No credits
                            </p>
                        </WithTooltip>
                    ) : statusCode >= 400 ? (
                        <WithTooltip message="Error">
                            <p className="flex items-center gap-1 text-red-500 p-1 rounded-md border text-xs cursor-pointer">
                                <XIcon className="w-4 h-4" /> Error
                            </p>
                        </WithTooltip>
                    ) : (
                        <p className="flex items-center gap-1">-</p>
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },

    {
        accessorKey: 'credits_consumed',
        meta: {
            name: () => 'API credits',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="API credits" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original && row.original.url.includes('jobs') ? (
                        <CreditsBadge credits={row.original.num_returned_jobs || 0} type="API" />
                    ) : (
                        <CreditsBadge credits={row.original.num_returned_companies || 0} type="API" />
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },
    {
        accessorKey: 'name',
        meta: {
            name: () => 'Summary',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Summary" />,
        cell: ({ row }) => {
            return <>{row.original && <p className="text-xs text-muted-foreground max-w-80">{row.original.name}</p>}</>
        },
        enableHiding: true,
        enableSorting: false,
    },
    {
        accessorKey: 'request',
        meta: {
            name: () => 'View request',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Request" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original.body && (
                        <APICurlButton
                            variant="outline"
                            buttonTitle="View request"
                            curl={getRequestCURL(row.original.body, row.original.url)}
                        />
                    )}
                </>
            )
        },
        enableHiding: true,
        enableSorting: false,
    },
]

export const getRequestCURL = (body: any, url: string) => {
    const pagination = { pageIndex: body.page, pageSize: body.limit, offset: body.offset }
    if (url.includes('jobs')) {
        return getJobsCURL(body, pagination, false)
    }
    if (url.includes('companies/search')) {
        return getCompaniesCURL(body, pagination, false)
    }
    if (url.includes('companies/technologies')) {
        return getTechDetailsCURL(body, pagination, false)
    }
    return ''
}
