import { getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL } from '@/lib/http-utils'
import { WebhookCount, webhookCountSchema } from '@/schemas/entities/webhook-count.schema'
import { WebhookExecution, webhookExecutionSchema } from '@/schemas/entities/webhook-execution.schema'
import { CreateWebhook, EditWebhook, Webhook, WebhookEventType, webhookSchema } from '@/schemas/entities/webhook.schema'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/webhooks'

export async function getAllWebhooks(): Promise<Webhook[]> {
    const res = await fetch(RESOURCE_URL, {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) throw new Error('Failed to fetch webhooks')
    return z.array(webhookSchema).parse(await res.json())
}

export async function getWebhook(id: number): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL + `/${id}`, {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) throw new Error('Failed to fetch webhook')
    return webhookSchema.parse(await res.json())
}

export async function createWebhook(data: CreateWebhook): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL, {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(data),
    })
    if (!res.ok) throw new Error('Failed to create webhook')
    return webhookSchema.parse(await res.json())
}

export async function editWebhook(id: number, data: EditWebhook): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL + `/${id}`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify(data),
    })
    if (!res.ok) throw new Error('Failed to update webhook')
    return webhookSchema.parse(await res.json())
}

export async function archiveWebhook(id: number): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL + `/${id}/archive`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) throw new Error('Failed to archive webhook')
    return webhookSchema.parse(await res.json())
}

export async function updateWebhookStatus(id: number, isActive: boolean): Promise<Webhook> {
    const res = await fetch(RESOURCE_URL + `/${id}/status`, {
        method: 'PATCH',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify({ is_active: isActive }),
    })
    if (!res.ok) throw new Error('Failed to update webhook status')
    return webhookSchema.parse(await res.json())
}

export async function testWebhook(url: string, event_type: WebhookEventType): Promise<number> {
    const res = await fetch(RESOURCE_URL + '/test', {
        method: 'POST',
        headers: getHTTPHeadersImpersonating(),
        body: JSON.stringify({ url, event_type }),
    })
    if (!res.ok) throw new Error('Failed to test webhook')
    return res.status
}

export async function getWebhookExecutions(
    id: number,
    offset: number = 0,
    limit: number = 10
): Promise<WebhookExecution[]> {
    const res = await fetch(RESOURCE_URL + `/${id}/executions?offset=${offset}&limit=${limit}`, {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) throw new Error('Failed to fetch webhook executions')
    return z.array(webhookExecutionSchema).parse(await res.json())
}

export async function getWebhookExecutionCount(id: number): Promise<number> {
    const res = await fetch(RESOURCE_URL + `/${id}/executions/count`, {
        method: 'GET',
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) throw new Error('Failed to export webhook executions')
    return res.json()
}

export async function getWebhookExecutionCountPerDay(
    start_datetime: Date,
    end_datetime: Date,
    webhook_id?: number
): Promise<WebhookCount[]> {
    const url = constructURL(RESOURCE_URL + '/executions/count', {
        start_datetime: start_datetime.toISOString(),
        end_datetime: end_datetime.toISOString(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        webhook_id: webhook_id?.toString(),
    })
    const res = await fetch(url, {
        headers: getHTTPHeadersImpersonating(),
    })
    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }
    const data = await res.json()
    return z.array(webhookCountSchema).parse(data)
}
