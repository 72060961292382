import { useAuthentication } from '@/components/hooks/authentication/use-authentication'
import { useMe } from '@/components/hooks/use-me'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/ui/base/alert-dialog'
import { useToast } from '@/components/ui/base/use-toast'
import { TeamMember } from '@/schemas/entities/team-members.schema'
import { removeTeamMember } from '@/services/team-member.service'
import { Button } from '@/shared/ui/base/button'
import { Trash } from 'lucide-react'
import { useState } from 'react'

function RemoveMembersButton({ member, invalidate }: { member: TeamMember; invalidate: () => void }) {
    const { me } = useMe()
    const { logout } = useAuthentication()
    const isLeavingTeam = me?.id === member.id
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { toast } = useToast()

    const handleRemove = async () => {
        setIsLoading(true)
        removeTeamMember(member.id)
            .then(() => {
                setOpen(false)
                setIsLoading(false)
                invalidate()
                if (isLeavingTeam) {
                    logout()
                } else {
                    toast({
                        title: 'Member removed',
                        description: 'The member has been removed from your team',
                    })
                }
            })
            .catch((error) => {
                setIsLoading(false)
                toast({
                    title: 'Error',
                    description: error.message,
                    variant: 'destructive',
                })
            })
    }

    const cancelAction = () => {
        setOpen(false)
    }

    const title = isLeavingTeam
        ? 'Are you sure you want to leave your team?'
        : `Are you sure you want to remove ${member.email} from your team?`

    if (!me) return null
    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
                <Button variant="outline" size="icon">
                    <Trash className="w-4 h-4" />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <Button variant="outline" onClick={cancelAction}>
                        Cancel
                    </Button>
                    <Button variant="destructive" isLoading={isLoading} onClick={handleRemove}>
                        {' '}
                        <Trash className="w-4 h-4 mr-2" />
                        {isLeavingTeam ? 'Leave team' : 'Remove member'}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default RemoveMembersButton
