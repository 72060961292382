import { useWebhook } from '@/components/hooks/use-webhook'
import { EditWebhookForm } from '@/components/ui/webhooks/edit-webhook-form'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export function WebhookEditPage() {
    useEffect(() => {
        document.title = 'Edit Webhook · Settings · TheirStack'
    }, [])
    const navigate = useNavigate()
    const { id: webhookId } = useParams<{ id: string }>()
    const { webhook } = useWebhook(Number(webhookId))

    if (!webhook) return null

    return (
        <div className="space-y-6">
            <EditWebhookForm
                webhook={webhook}
                onCancel={() => navigate(`/settings/webhooks/${webhookId}`)}
                onSuccess={() => navigate(`/settings/webhooks/${webhookId}`)}
            />
        </div>
    )
}
