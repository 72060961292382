import { useImpersonating } from '@/components/hooks/authentication/use-impersonating'
import {
    getCreditsFromOldInvoices,
    getLatestInvoiceConsumption,
    isCancelationAvailable,
    isPlanIncomplete,
    Plan,
} from '@/schemas/entities/plan'
import { getBillingPortalSessionUrl } from '@/services/billing.service'
import { Badge } from '@/shared/ui/base/badge'
import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { formatDateTimeStringToReadableDate } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { capitalizeFirstLetter } from '@/shared/utils/string-utils'
import { Link } from 'react-router-dom'
import AdminActions from './admin-actions'

export function PlanCard({
    plan,
    isCancellationLoading,
    cancelSubscription,
}: {
    plan: Plan
    isCancellationLoading: boolean
    cancelSubscription: (subscription_id: string) => void
}) {
    const { isImpersonating } = useImpersonating()
    const getRemainingDays = (plan: Plan) => {
        if (!plan.current_period_end) return 0
        const currentDate = new Date()
        const currentPeriodEnd = new Date(plan.current_period_end)
        return Math.ceil((currentPeriodEnd.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24))
    }

    const lastestInvoiceCosumption = getLatestInvoiceConsumption(plan)

    const { used_credits: used_api_credits, total_credits: total_api_credits } = plan.recurring
        ? getCreditsFromOldInvoices(plan, 'api')
        : { used_credits: 0, total_credits: 0 }
    const { used_credits: used_ui_credits, total_credits: total_ui_credits } = plan.recurring
        ? getCreditsFromOldInvoices(plan, 'ui')
        : { used_credits: 0, total_credits: 0 }

    return (
        <Card key={plan.name} className="flex flex-row gap-1 p-2 items-center justify-between">
            <div className="flex flex-col gap-2 items-start justify-start">
                <p className="text-md font-medium ">
                    {plan.name} plan
                    {plan.status != 'active' && (
                        <Badge variant="warning" className="ml-2 text-xs">
                            {capitalizeFirstLetter(plan.status)}
                        </Badge>
                    )}
                </p>

                <span className="text-xs text-muted-foreground">
                    {formatDateTimeStringToReadableDate(plan.current_period_start ?? '')} -{' '}
                    {formatDateTimeStringToReadableDate(plan.current_period_end ?? '')}
                    {plan.recurring &&
                        plan.status == 'active' &&
                        !plan.cancel_at_period_end &&
                        ` (renews in ${getRemainingDays(plan)} days)`}
                    {!isCancellationLoading &&
                        plan.status == 'active' &&
                        plan.cancel_at_period_end == true &&
                        ` (active until ${formatDateTimeStringToReadableDate(plan.current_period_end ?? '')} - cancellation scheduled)`}
                </span>

                <div className="flex flex-col gap-2 items-start justify-start">
                    <p className="text-xs">
                        {plan.credits_used_current_period != null && (
                            <span>{formatNumberWithComma(lastestInvoiceCosumption.used_ui_credits)} used of </span>
                        )}
                        {formatNumberWithComma(lastestInvoiceCosumption.ui_credits)} company credits
                        {total_ui_credits > 0 && (
                            <span className="text-xs text-muted-foreground">
                                {' '}
                                ({formatNumberWithComma(used_ui_credits)} used of{' '}
                                {formatNumberWithComma(total_ui_credits)} rolled over from previous months)
                            </span>
                        )}
                    </p>
                    <p className="text-xs">
                        {plan.api_credits_used_current_period != null && (
                            <span>{formatNumberWithComma(lastestInvoiceCosumption.used_api_credits)} used of </span>
                        )}
                        <span>{formatNumberWithComma(lastestInvoiceCosumption.api_credits)} API credits</span>
                        {total_api_credits > 0 && (
                            <span className="text-xs text-muted-foreground">
                                {' '}
                                ({formatNumberWithComma(used_api_credits)} used of{' '}
                                {formatNumberWithComma(total_api_credits)} rolled over from previous months)
                            </span>
                        )}
                    </p>
                    {plan.datasets && plan.datasets.length > 0 && (
                        <p className="text-xs">
                            {plan.datasets.length} datasets: {plan.datasets.join(', ')}
                        </p>
                    )}
                </div>
            </div>
            <div className="flex flex-row gap-2 items-center justify-start">
                {plan.name != 'Free' && plan.recurring && plan.status === 'active' && (
                    <Button
                        variant="outline"
                        onClick={async () => {
                            const url = await getBillingPortalSessionUrl()
                            window.open(url)
                        }}
                    >
                        Upgrade plan
                    </Button>
                )}
                {isPlanIncomplete(plan) && (
                    <Button variant="default" asChild>
                        <Link to={plan.hosted_invoice_url || ''} target="_blank">
                            Complete payment
                        </Link>
                    </Button>
                )}
                {!isCancellationLoading && isCancelationAvailable(plan) && (
                    <Button
                        variant="destructive_outline"
                        onClick={() => cancelSubscription(plan.subscription_id ?? '')}
                    >
                        Cancel plan
                    </Button>
                )}
                {isImpersonating && <AdminActions plan={plan} />}
            </div>
        </Card>
    )
}
