import { Button } from '@/shared/ui/base/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/shared/ui/base/dialog'
import { useState } from 'react'

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { useToast } from '@/components/ui/base/use-toast'
import { inviteTeamMember } from '@/services/team-member.service'
import { EVENT_ADD_TEAM_MEMBER_CLICK, EVENT_ADD_TEAM_MEMEBER_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const formSchema = z.object({
    email: z
        .string()
        .min(1, 'Email is required')
        .email('Invalid email address')
        .transform((email) => email.toLowerCase()),
})

function InviteMemberButton({ onSuccess }: { onSuccess: () => void }) {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: '',
        },
    })
    const { toast } = useToast()
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function onSubmit(data: z.infer<typeof formSchema>) {
        setIsLoading(true)
        inviteTeamMember(data.email)
            .then(() => {
                sendEvent(EVENT_ADD_TEAM_MEMBER_CLICK)
                form.reset()
                toast({ title: 'Member added' })
                setOpen(false)
                onSuccess()
            })
            .catch((error) => {
                toast({ title: 'Failed to add member', description: error.message, variant: 'destructive' })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild onClick={() => sendEvent(EVENT_ADD_TEAM_MEMEBER_MODAL_VIEW)}>
                <Button variant="outline">Invite members</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Invite team members</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form className="grid  max-w-sm items-center gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel htmlFor="email" className="col-span-1">
                                        Email
                                    </FormLabel>
                                    <FormControl>
                                        <Input type="string" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <DialogFooter className="pt-6">
                            <Button type="submit" size="sm" isLoading={isLoading}>
                                Invite
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default InviteMemberButton
