import { EVENT_API_CURL_MODAL_COPY, sendEvent } from '@/services/tracking.service'
import { Button } from '@/shared/ui/base/button'
import { Copy, ExternalLink } from 'lucide-react'

import { useImpersonating } from '@/components/hooks/authentication/use-impersonating'
import { useToast } from '@/components/ui/base/use-toast'
import { AdminCurlActions } from '@/components/ui/table/actions/action-get-api-curl/admin-curl-actions'
import { UserWithTeam } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { NavigateToPage } from '../../base/paginated-sheet'
import { MAKE_PAGE_SLUG, N8N_PAGE_SLUG } from '../../instances/api-webhook-sheet'
import { CodeBlock } from './components/code-block'

export default function API({
    curl,
    navigateToPage,
    className,
}: {
    curl: string
    navigateToPage?: NavigateToPage
    className?: string
}) {
    const { isImpersonating } = useImpersonating()
    const { toast } = useToast()
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    })

    const getCurlWithToken = (curl: string) => {
        let code = curl
        if (me) {
            code = code.replace('<api_key>', me.token)
        }
        return code
    }

    const copyCurlToClipboard = (_curl: string, title: string) => {
        navigator.clipboard.writeText(_curl)
        toast({ title })
    }

    return (
        <div className={`${className}`}>
            <div className="flex flex-row justify-between my-2">
                <div className="flex gap-2 flex-wrap">
                    <Link to={`/settings/api-key`} target="_blank">
                        <Button size="xs" variant="outline">
                            <ExternalLink className="mr-2 h-4 w-4" />
                            API Key
                        </Button>
                    </Link>
                    <Button
                        size="xs"
                        variant={'outline'}
                        onClick={() => {
                            sendEvent(EVENT_API_CURL_MODAL_COPY)
                            window.open('https://api.theirstack.com', '_blank')
                        }}
                    >
                        <ExternalLink className="mr-2 h-4 w-4" />
                        API Docs
                    </Button>
                </div>
                <div className="flex gap-2 justify-end flex-wrap">
                    {navigateToPage && (
                        <div className="flex flex-row gap-2">
                            <IntegrationCard
                                app="Make"
                                pageId={MAKE_PAGE_SLUG}
                                image_path="/tools/make-logo.png"
                                navigateToPage={navigateToPage}
                            />
                            <IntegrationCard
                                app="N8N"
                                pageId={N8N_PAGE_SLUG}
                                image_path="/tools/n8n-logo.png"
                                navigateToPage={navigateToPage}
                            />
                        </div>
                    )}

                    {(me && isImpersonating) || me?.is_superuser ? (
                        <AdminCurlActions
                            curl={curl}
                            copyCurlToClipboard={copyCurlToClipboard}
                            me={me}
                            isImpersonating={isImpersonating}
                        />
                    ) : (
                        <Button
                            size="xs"
                            variant="outline"
                            onClick={() => copyCurlToClipboard(getCurlWithToken(curl), 'Copied to clipboard')}
                        >
                            <Copy className="mr-2 h-4 w-4" />
                            cURL
                        </Button>
                    )}
                </div>
            </div>
            <CodeBlock curl={curl} />
        </div>
    )
}

function IntegrationCard({
    app,
    pageId,
    image_path,
    navigateToPage,
}: {
    app: string
    pageId: string
    image_path: string
    navigateToPage: (pageId: string) => void
}) {
    return (
        <Button
            size="xs"
            variant="outline"
            className="flex flex-row items-center justify-start gap-2"
            onClick={() => navigateToPage(pageId)}
        >
            <img src={image_path} className="h-4 w-4" />
            {app}
        </Button>
    )
}
